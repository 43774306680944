import React from "react";
import Card from "./card";
import { useSelector } from "react-redux";

const CardList = () => {
  const PrivateTours = useSelector((state) => state.Products.PrivateTours);

  return (
    // <section className="tour-slider" id="card-list">
    //   <div className="container">
    //     <div className="row justify-content-center">
    //       <div
    //         className="col-12 col-md-10 col-lg-8 text-center"
    //         style={{ padding: "30px" }}
    //       >
    //         <h2
    //           className="display-3"
    //           style={{ fontSize: "30px", fontWeight: "700" }}
    //         >
    //           PRIVATE TOURS IN ICELAND
    //         </h2>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="container">
    //     <div
    //       className="row"
    //       style={{
    //         display: "flex",
    //         flexWrap: "wrap",
    //         justifyContent: "center",

    //       }}
    //     >
    //       {PrivateTours.map((tour, index) => (
    //         <div
    //           key={index}
    //           className="col-6 col-md-6 col-xl-4 p-2"
    //           style={{
    //             flexBasis: "calc(33.33% - 20px)",
    //             maxWidth: "calc(33.33% - 20px)",
    //           }}
    //         >
    //           <Card
    //             imageUrl={tour.imageUrl}
    //             title={tour.name}
    //             // description={tour.description}
    //             price={tour.price}
    //             id={tour.id}
    //           />
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // </section>

    <section className="tour-slider" id="card-list">
      <div className="container mx-auto px-4">
        <div className="text-center py-8">
          <h1 className="text-3xl font-bold">PRIVATE TOURS IN ICELAND</h1>
        </div>
        <div className="flex flex-wrap justify-center">
          {PrivateTours.map((tour, index) => (
            <div
              key={index}
              className="w-full sm:w-1/2 lg:w-1/4 p-2"
              // style={{ maxWidth: "calc(33.33% - 20px)" }}
            >
              <Card
                imageUrl={tour.imageUrl}
                title={tour.name}
                price={tour.price}
                id={tour.id}
                currency={tour.currency}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CardList;

import { configureStore } from "@reduxjs/toolkit";
import UerReducer from "./slice/userSlice";
import ProductReducer from "./slice/Products";

export const store = configureStore({
  reducer: {
    Users: UerReducer,
    Products: ProductReducer,
  },
});

import React from "react";
import { Button, Rate, Tooltip } from "antd";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import GroupIcon from '@mui/icons-material/Group';

const CardStyle = styled.div`
  .ant-btn:hover {
    background: #fd9832 !important;
    color: white !important;
    border: none;
  }
`;

export default function Card(props) {
  const navigate = useNavigate();
  const { name, type, price, detail, image, id, discount, features, currency } =
    props.item;
  const discountPercentage = ((discount - price) / discount) * 100;
  return (
    <CardStyle className="">
      <div className="w-full">
        <div className="bg-white rounded-2xl shadow-custom">
          <div
            className="overflow-hidden rounded-t-2xl"
            style={{ position: "relative" }}
          >
            <Link to={`/detail-tour/${id}/${name}`}>
              {discount != 0 && (
                <div
                  style={{
                    width: "80px",
                    height: "30px",
                    position: "absolute",
                    top: "20px",
                    background: "#BB1213",
                    color: "white",
                    textAlign: "center",
                    zIndex: "1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "0 25px 25px 0",
                  }}
                >
                  <p style={{ fontWeight: "700" }}>
                    {discountPercentage.toFixed(0)} % Off
                  </p>
                </div>
              )}

              {features != "" && (
                <div
                  style={{
                    width: "fit-content",
                    height: "30px",
                    position: "absolute",
                    top: "55px",
                    padding: "10px",
                    background: "#0D5382",
                    color: "white",
                    textAlign: "center",
                    zIndex: "1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "0 25px 25px 0",
                  }}
                >
                  <p style={{ fontWeight: "700" }}>{features}</p>
                </div>
              )}
              <img
                style={{ height: "200px" }}
                className="w-full rounded-t-2xl hover:scale-110 transition-all duration-300 ease-in-out transform"
                src={image}
                alt="image"
              />
            </Link>
          </div>
          <div className="p-5">
            <div>
              <div className="mb-[5px]">
                {/* <Rate
                  disabled
                  defaultValue={star}
                  count={star}
                  style={{
                    color: "#FA5636",
                  }}
                /> */}
              </div>
              <div className="mb-[5px]">
                <Link to={`/detail-tour/${id}/${name}`}>
                  <Tooltip title={name}>
                    <h1 className="hover:text-primary font-bold text-lg h-[40px] overflow-hidden whitespace-nowrap overflow-ellipsis">
                      {name}
                    </h1>
                  </Tooltip>
                </Link>
              </div>
              <p className="text-p">{type}</p>
              <p
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  height: "25px",
                  whiteSpace: "nowrap",
                }}
                className="text-p"
              >
                {detail}
              </p>
              <div className="participation" style={{margin: "5px 0 0 0"}}><GroupIcon/> 48</div>
            </div>
            <div className="mt-[15px] pt-[15px] border-t border-solid border-[#dedede]">
              <div
                className="flex items-center"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <Button
                    onClick={() => navigate(`/detail-tour/${id}/${name}`)}
                  >
                    View Tour
                  </Button>
                </div>{" "}
                <div>
                  {discount != 0 && (
                    <div
                      style={{ fontSize: "9px" }}
                      className="text-p mr-[5px]"
                    >
                      <del>
                        {currency}: {discount}
                      </del>
                    </div>
                  )}
                  <div
                    style={{ fontSize: "14px" }}
                    className="font-bold mr-[5px]"
                  >
                    {currency}. {price}
                  </div>
                  {/* <span className=" text-p"> / night</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CardStyle>
  );
}

import airportImage from "../assets/tours/multidaytour/All_Images/airportimg.jpg";


export const dayTour = [
  {
    currency: "ISK",
    name: "SNORKELING IN SILFRA FISSURE",
    type: "SNORKELING",
    detail: "A Day Tour At Thingvellir National Park",
    image: require("../assets/tours/daytour/All_images/1.jpg"),
    reviews: 27,
    price: 22900,
    discount: 0,
    id: 35,
    images: {
      image1: require("../assets/tours/daytour/All_images/2.jpg"),
      image2: require("../assets/tours/daytour/All_images/3.webp"),
      image3: require("../assets/tours/daytour/All_images/4.webp"),
      image4: require("../assets/tours/daytour/All_images/5.webp"),
    },
    description:
      "Float over lava, swim between large volcanic canyons and discover the crystal clear waters that flow between the North American and Eurasian continents. Snorkeling in Iceland is an adventure you will find unusual and an experience never to forget!",
    features: "Popular",
    highlights: [
      "Explore Þingvellir National Park",
      "Snorkel between two tectonic plates",
      "Get free underwater photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "Into the Glacier Experience",
    type: "MAN-MADE ICE TUNNEL",
    detail: "Visit the man-made ice tunnel in Langjökull glacier",
    image: require("../assets/tours/daytour/All_images/6.jpg"),
    reviews: 8,
    price: 21920,
    discount: 0,
    id: 36,
    images: {
      image1: require("../assets/tours/daytour/All_images/7.jpg"),
      image2: require("../assets/tours/daytour/All_images/8.jpg"),
      image3: require("../assets/tours/daytour/All_images/9.jpg"),
      image4: require("../assets/tours/daytour/All_images/10.jpg"),
    },
    description:
      "Explore the second-largest glacier in Iceland from the inside! This journey takes you on a guided tour in a monster truck to the entrance of the Langjokull Ice Cave. The man-made tunnel in Langjökull glacier is the largest of its kind in the world. We’ll explore the tunnels for one hour, stopping at the built-in chapel. Venture deep into the heart of Iceland’s Ice Age not far from Reykjavik! Meet your tour guide on location or at a pick-up point in Reykjavik. Select your preferred option when you book.",
    features: "",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "Skaftafell Blue Ice Cave & Glacier Hike Tour",
    type: "Day Tour",
    image: require("../assets/tours/daytour/All_images/16.webp"),
    detail: "Explore a Natural Ice Cave & Hike on Europe's Largest Glacier",
    reviews: 39,
    price: 19899,
    id: 38,
    images: {
      image1: require("../assets/tours/daytour/All_images/17.jpg"),
      image2: require("../assets/tours/daytour/All_images/18.webp"),
      image3: require("../assets/tours/daytour/All_images/19.jpg"),
      image4: require("../assets/tours/daytour/All_images/20.webp"),
    },
    description:
      "Exploring the largest glacier in Europe is a truly once-in-a-lifetime experience. This ice cave and glacier hike tour allows visitors to explore the stunning natural formations and crevasses on Vatnajökull with an expert guide.The journey to the beautiful Skaftafell blue ice cave requires quite a climb, so it’s time to strap on your crampons! The ice hike is an incredible addition to the cave experience, providing awe-inspiring views across the ice giant. Double up your glacier experience with this incredible combo tour, the epitome of a volcanic experience in Iceland.",
    discount: 0,
    features: "",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "Golden Circle Superior",
    type: "Day Tour",
    image: require("../assets/tours/daytour/All_images/26.webp"),
    detail: "Icelandic Hot Springs, Gullfoss & Thingvellir",
    reviews: 6,
    price: 12990,
    id: 40,
    images: {
      image1: require("../assets/tours/daytour/All_images/27.jpg"),
      image2: require("../assets/tours/daytour/All_images/28.jpg"),
      image3: require("../assets/tours/daytour/All_images/29.webp"),
      image4: require("../assets/tours/daytour/All_images/30.jpg"),
    },
    description:
      "Fill your day exploring the famous Golden Circle in a small group setting with some added flair. Visit the unmissable Thingvellir National Park, Gullfoss Waterfall, Kerid Crater Lake, and Geysir Hot Springs. We'll also stop at off-the-beaten-track spots like Hveragerði a geothermal town in Southwest Iceland. You'll get a real feel for the Land of Fire and Ice in just one day.Be amazed by gushing geysers, powerful waterfalls, and historical sites. Pack your sense of adventure and explore the best Iceland has to offer!",
    discount: 0,
    features: "Popular",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "Northern Lights Super Jeep Tour",
    type: "Day Tour",
    image: require("../assets/tours/daytour/All_images/36.jpg"),
    detail: "Chase the Auroras in a Super Jeep from Reykjavik",
    reviews: 45,
    price: 23990,
    id: 42,
    images: {
      image1: require("../assets/tours/daytour/All_images/37.webp"),
      image2: require("../assets/tours/daytour/All_images/38.jpg"),
      image3: require("../assets/tours/daytour/All_images/39.jpg"),
      image4: require("../assets/tours/daytour/All_images/40.jpg"),
    },
    description:
      "Escape the big tour buses and go off the beaten track to hunt Iceland's magical Northern Lights. We pick you up and drive you around in a stylish and comfortable Super Jeep!Our Northern Lights chase on a Super Jeep is the ultimate experience in Iceland. Venturing off the beaten track in the powerful Super Jeep grants you exclusive access to the best spots in Iceland to admire the dancing lights in all their glory. We’ll drive away from the crowds and light pollution. Wrap yourself in a blanket and enjoy a cup of hot cocoa with chocolate while gazing up at the Aurora Borealis in the heavens!Your guide will tell you all about this natural wonder and take free photographs so you can always keep the memory alive!",
    discount: 0,
    features: "Popular",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "Northern Lights Explorer & Aurora Museum",
    type: "Day Tour",
    image: require("../assets/tours/daytour/All_images/41.jpg"),
    detail: "Northern Lights Tour with a Museum Entrance",
    reviews: 45,
    price: 25990,
    discount: 0,
    id: 43,
    images: {
      image1: require("../assets/tours/daytour/All_images/42.jpg"),
      image2: require("../assets/tours/daytour/All_images/43.jpg"),
      image3: require("../assets/tours/daytour/All_images/44.jpg"),
      image4: require("../assets/tours/daytour/All_images/45.jpg"),
    },
    description:
      "The Northern Lights – one of nature’s greatest shows on Earth – tops the bucket lists of travelers all over the globe. Join us on a thrilling hunt from Reykjavik to spot this ghostly glow in the night sky. We escape the city’s artificial lights and drive into the deep darkness of the countryside. Your guide will keep a close eye on weather conditions to find the perfect spot to admire the Aurora Borealis. Your encounter with these ephemeral lights will make for a magical night!Your guide will take free photos of you and the aurora for your travel album, so you’ll always have a reminder of this dazzling moment!",
    features: "Popular",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "Snaefellsnes & Kirkjufell Small Group Tour",
    type: "Day Tour",
    image: require("../assets/tours/daytour/All_images/46.webp"),
    detail: "Experience the Best of West Iceland in a Single Day",
    reviews: 20,
    price: 19900,
    discount: 0,
    id: 44,
    images: {
      image1: require("../assets/tours/daytour/All_images/48.webp"),
      image2: require("../assets/tours/daytour/All_images/49.webp"),
      image3: require("../assets/tours/daytour/All_images/46.webp"),
      image4: require("../assets/tours/daytour/All_images/50.webp"),
    },
    description:
      "Discover the West of Iceland on the Snæfellsnes & Kirkjufell small group tour. Explore the spectacular lava-coated Snæfellsnes Peninsula, walk across the black pebbles at Djúpalónssandur, and admire the distinct Mount Kirkjufell. Treat yourself to landscape out of this world under the direction of our expert guides.Fast becoming one of the most visited places in Iceland, the Snæfellsnes Peninsula was rated the most scenic destination in Europe by Luxury Travel Guide, with West Iceland being awarded the winter destination of the year. This place is magical and spellbinding, with moss-cloaked lava fields, picturesque mountains, gorgeous black sand beaches, roaring ocean waves, charming little villages, and sea cliffs.",
    features: "",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "Crystal Ice Cave Tour with Super Jeep Ride",
    type: "Day Tour",
    image: require("../assets/tours/daytour/All_images/51.webp"),
    detail: "Bluest Ice Cave in Iceland from Jökulsárlón Glacier Lagoon",
    reviews: 24,
    price: 19990,
    discount: 0,
    id: 45,
    images: {
      image1: require("../assets/tours/daytour/All_images/52.webp"),
      image2: require("../assets/tours/daytour/All_images/53.webp"),
      image3: require("../assets/tours/daytour/All_images/54.webp"),
      image4: require("../assets/tours/daytour/All_images/55.webp"),
    },
    description:
      "Have the experience of a lifetime as you witness crystal blue ice unlike any other in Iceland. This tour is a must-see for people who want to witness the country’s bluest, most captivating ice cave in recent years. Get up close and personal to incredible shades of icy blue while you walk into the depths of the mighty Vatnajokull, Europe’s largest Glacier. Grab at the chance to take fantastic photos to make this special ice cave adventure last a lifetime!",
    features: "Popular",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "South Shore Adventure",
    type: "Day Tour",
    image: require("../assets/tours/daytour/All_images/56.jpg"),
    detail: "South Coast of Iceland - Small Group Tour",
    reviews: 6,
    price: 16756,
    discount: 0,
    id: 46,
    images: {
      image1: require("../assets/tours/daytour/All_images/57.jpg"),
      image2: require("../assets/tours/daytour/All_images/58.webp"),
      image3: require("../assets/tours/daytour/All_images/59.webp"),
      image4: require("../assets/tours/daytour/All_images/60.webp"),
    },
    description:
      "This tour gives you the chance to see all the most beautiful sights on the south shore. There is a good reason the southern parts of Iceland are such popular destinations. Locals have been admiring it for centuries, now it’s your turn. Enjoy the mesmerizing waterfall of Skógar, go behind the magical Seljalandsfoss, walk on the volcanic black sand beach of Reynisfjara and get in close contact with a glacier.",
    features: "",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "Glacier Wonders - Easy Glacier Hike in Skaftafell",
    type: "Day Tour",
    image: require("../assets/tours/daytour/All_images/66.jpg"),
    detail:
      "Will take you to a great moving glacier in Vatnajökull National Park",
    reviews: 11,
    price: 13391,
    discount: 0,
    id: 48,
    images: {
      image1: require("../assets/tours/daytour/All_images/67.jpg"),
      image2: require("../assets/tours/daytour/All_images/68.jpg"),
      image3: require("../assets/tours/daytour/All_images/69.jpg"),
      image4: require("../assets/tours/daytour/All_images/70.jpg"),
    },
    description:
      "This breathtaking glacier hike from Skaftafell will take you to a great moving glacier in Vatnajökull National Park. Book a tour and start exploring!Glacier Wonders is a superb glacier hiking tour from Skaftafell into the magnificent ice world of Falljökull glacier, a tongue of the mighty Vatnajökull, the largest glacier in Europe. Your certified glacier guide will show you astonishing crevasses which wind through the ice and incredible glistening ice formations.. During this tour, you will learn how a living glacier moves and why this magical ice kingdom is constantly changing.",
    features: "",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "Snowmobile & Ice Cave Tour In Iceland",
    type: "Day Tour",
    image: require("../assets/tours/daytour/All_images/86.jpg"),
    detail:
      "Experience Snowmobiling On Langjökull Glacier And Ice Cave Exploration On The Same Day!",
    reviews: 71,
    price: 40100,
    discount: 0,
    id: 52,
    images: {
      image1: require("../assets/tours/daytour/All_images/87.jpg"),
      image2: require("../assets/tours/daytour/All_images/88.jpg"),
      image3: require("../assets/tours/daytour/All_images/89.jpg"),
      image4: require("../assets/tours/daytour/All_images/90.jpg"),
    },
    description:
      "Hop on a snowmobile, and explore the stunning landscape of Langjökull Glacier and the wonders of a naturally-formed ice cave.riding a snowmobile on Iceland’s second largest glacier with the added highlight of a visit to a magical natural Langjökull Ice Cave!",
    features: "",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "Golden Circle & Secret Lagoon",
    type: "Day Tour",
    image: require("../assets/tours/daytour/All_images/101.jpg"),
    detail:
      "Thingvellir, Gullfoss waterfall, Geysir hot spring and bathing in the Secret Lagoon natural hot spring",
    reviews: 11,
    price: 17990,
    discount: 0,
    id: 55,
    images: {
      image1: require("../assets/tours/daytour/All_images/102.jpg"),
      image2: require("../assets/tours/daytour/All_images/103.jpg"),
      image3: require("../assets/tours/daytour/All_images/104.jpg"),
      image4: require("../assets/tours/daytour/All_images/105.jpg"),
    },
    description:
      "Soak in a natural pool and explore four of the most popular attractions in Iceland. The Golden Circle & Secret Lagoon Iceland offer a great value day tour in a small group setting.It is a perfect Icelandic experience giving you a chance to visit waterfalls, geysers, and historical sights and soak in a natural hot spring.Explore the famous sites of Thingvellir National Park, Gullfoss waterfall, and the Geysir hot spring area with a massive Icelandic twist.",
    features: "Family Friendly",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
];

export const multiDayTour = [
  {
    currency: "ISK",
    name: "6 Days Around Iceland Adventure",
    type: "Multi-Day Tour",
    image: require("../assets/tours/multidaytour/All_Images/1.jpg"),
    detail:
      "RING ROAD, GOLDEN CIRCLE, MYVATN, HOT SPRING, WATERFALLS & GLACIER LAGOON",
    reviews: 159,
    price: 27269,
    discount: 292691,
    id: 1,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/2.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/3.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/4.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/5.jpg"),
    },
    description:
      "Explore some of the most incredible locations around Iceland. From the Golden Circle to the South Coast, Jökulsárlón Glacier Lagoon, and all the way to Akureyri, the 'Capital of the North'! Try exciting activities like glacier hike, ice cave exploration, and whale watching!",
    features: "Popular",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "2 Day South Coast, Ice Cave, Jokulsarlon Glacier Lagoon Tour",
    type: "Multi-Day Tour",
    image: require("../assets/tours/multidaytour/All_Images/6.jpg"),
    detail:
      "Guided Ice Cave, Jokulsarlon Glacier Lagoon, South Coast Sightseeing tour from Reykjavik",
    reviews: 36,
    price: 80990,
    discount: 90000,
    id: 2,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/7.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/8.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/9.webp"),
      image4: require("../assets/tours/multidaytour/All_Images/10.jpg"),
    },
    description:
      "Lace up your hiking boots and join us on this two-day trip for close encounters with the wonders of Icelandic nature. Explore the South Coast with its iconic waterfalls and black sand beaches, and be the first to step into a blue ice cave of Europe’s largest ice cap. To top it all off, we’ll have ample moments to chase the magical, ever-elusive Northern Lights.    ",
    features: "Most Popular",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "2-Day Tour to Snæfellsnes Peninsula & Borgarfjörður Fjord",
    type: "Multi-Day Tour",
    image: require("../assets/tours/multidaytour/All_Images/11.jpg"),
    detail:
      "Mount Kirkjufell, Hraunfossar, Reykholt & Deildartunguhver Hot Spring",
    reviews: 4,
    price: 67990,
    discount: 0,
    id: 3,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/12.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/13.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/14.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/15.jpg"),
    },
    description:
      "On this unforgettable two-day tour, soak up the spectacular views of Snæfellsnes Peninsula, walk on the black pebble beach of Djúpalónssandur, witness Europe’s most powerful hot spring, and get in touch with diverse Icelandic nature.And for an extra touch of magic during the winter months, we include a mesmerizing Northern Lights chase in your adventure!",
    features: "",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "5 Day Snæfellsnes Peninsula And South Iceland Tour",
    type: "Multi-Day Tour",
    image: require("../assets/tours/multidaytour/All_Images/16.jpg"),
    detail: "See the best of Iceland on this award-winning tour",
    reviews: 11,
    price: 219990,
    discount: 239990,
    id: 4,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/17.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/18.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/19.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/20.jpg"),
    },
    description:
      "Our five-day tour from West to East is a great way to explore some of the most staggering and coveted sights in Iceland. Snaefellsnes Peninsula, named the ultimate winter destination by Travel & Leisure, invites you on a journey through unique and offbeat rural landscapes. Besides, the South Coast is a sightseeing paradise with a vast amount of marvels: waterfalls, glaciers, national parks, and black volcanic beaches - sights that’ll take your breath away.",
    features: "Special Offer",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "3 Day South Coast & Golden Circle Tour",
    type: "Multi-Day Tour",
    image: require("../assets/tours/multidaytour/All_Images/21.jpg"),
    detail:
      "Diamond Beach, Jokulsarlon Glacier Lagoon, Ice Cave & Northern Lights",
    reviews: 67,
    price: 132990,
    discount: 0,
    id: 5,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/22.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/23.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/24.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/25.webp"),
    },
    description:
      "This small-group, 3-day tour is fabulous for those with limited time to enjoy the best of Iceland. We highlight the most popular adventures: spectacular waterfalls, glacier hikes, and an ice cave at Vatnajokull, Europe’s biggest glacier. Your nights are far away from towns and light pollution, giving you the best chance of seeing the glittering Northern Lights during wintertime.",
    features: "",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "4 Day Northern Lights, Snaefellsnes & Glacier Lagoon",
    type: "Multi-Day Tour",
    image: require("../assets/tours/multidaytour/All_Images/26.webp"),
    detail: "South Coast, Ice Cave, Jokulsarlon Glacier Lagoon & West Iceland",
    reviews: 6,
    price: 179990,
    discount: 0,
    id: 6,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/27.webp"),
      image2: require("../assets/tours/multidaytour/All_Images/28.webp"),
      image3: require("../assets/tours/multidaytour/All_Images/29.webp"),
      image4: require("../assets/tours/multidaytour/All_Images/30.jpg"),
    },
    description:
      "Travel from the west to the east, exploring the raw rural landscapes of Iceland with lava deserts and leftover moraine. Hike on a glacier and marvel at the biggest hot spring in Europe on this unforgettable 4-day adventure.",
    features: "",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "7 Day Complete Iceland",
    type: "Multi-Day Tour",
    image: require("../assets/tours/multidaytour/All_Images/31.jpg"),
    detail:
      "The Ring Road, Golden Circle, Jökulsárlón Glacier Lagoon, Snæfellsnes, Northern Lights and more",
    reviews: 11,
    price: 304191,
    discount: 324191,
    id: 7,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/32.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/33.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/34.webp"),
      image4: require("../assets/tours/multidaytour/All_Images/35.jpg"),
    },
    description:
      "The very best of sensational Iceland in just seven days. Sounds good, right? During this tour, we will show you the most stunning and legendary places, from incredible waterfalls and erupting geysers to the Golden Circle to Snæfellsnes Peninsula. There are numerous awaiting you, including whale watching, glacier hiking, visiting ice caves, hot springs, steaming geothermal areas, and chasing for Northern Lights.This tour will show you the most majestic sights and scenery around the famous Ring Road and the delights of the Snæfellsnes peninsula. We will take you to steep fjords with ragged-toothed mountains reaching into the sky, black sand beaches, lava fields, and charming fishing villages, whose histories are deeply woven into Icelandic life's fabric and beyond.Snæfellsnes in West Iceland was celebrated as a top winter destination",
    features: "",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "5 Day South Coast & Northern Lights Tour",
    type: "Multi-Day Tour",
    image: require("../assets/tours/multidaytour/All_Images/36.jpg"),
    detail:
      "Northern Lights Vacation Package with Sightseeing on the South Coast",
    reviews: 2,
    price: 214990,
    discount: 224990,
    id: 8,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/37.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/38.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/39.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/40.jpg"),
    },
    description:
      "Our 5-day South Coast and Northern Lights tour encompasses the most famous sights in South Iceland and takes you to the best spots to watch the Northern Lights!Throughout the five days, visit the highlights of the iconic Golden Circle, explore an ice cave, walk on Diamond Beach, and see the majestic waterfalls! After sightseeing, join the exciting chase for the Northern Lights and watch the natural light show at the most unexpected locations!",
    features: "Family Friendly",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "8 Day Iceland Ring Road Tour",
    type: "Multi-Day Tour",
    image: require("../assets/tours/multidaytour/All_Images/41.webp"),
    detail:
      "Ring Road, Golden Circle, Myvatn, Hot Springs, Waterfalls & Glacier Lagoon",
    reviews: 24,
    price: 319491,
    discount: 359491,
    id: 9,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/42.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/43.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/44.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/45.jpg"),
    },
    description:
      "We put together this amazing eight-day tour for people who really want to make the most of their time in Iceland and enjoy Reykjavík. Visit a shimmering glacier, spouting geysers, incredible volcanoes, and stunning waterfalls, and go whale watching in the north. Enjoy the hot Vök Baths and stray off the beaten track, building unforgettable memories. In winter, climb through ice caves and chase the Northern Lights. Your first and last nights are spent in Reykjavík, giving you the chance to explore this fantastic city on your own.",
    features: "Popular",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "4 Day - Golden Circle, South Coast, Ice Cave & Recently Erupted Volcano",
    type: "Multi-Day Tour",
    image: require("../assets/tours/multidaytour/All_Images/46.jpg"),
    detail: "Guided Tour with Reykjavík Pick-up",
    reviews: 4,
    price: 179990,
    discount: 199990,
    id: 10,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/47.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/48.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/49.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/50.jpg"),
    },
    description:
      "This magical four-day tour will take you to some of the most famous places in the land of fire and ice. We’ll go on the iconic Golden Circle route, see Iceland’s famous South Coast, explore mesmerizing ice caves, walk on vast glaciers and see the recently erupted volcano site.Join us on a four-day adventure, exploring Iceland's most stunning locations, including the Golden Circle, Reynisfjara Black Sand Beach, the newly erupted volcano site, and Diamond Beach, just to name a few.",
    features: "",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "8 Day - Iceland Northern Lights Tour",
    type: "Multi-Day Tour",
    image: require("../assets/tours/multidaytour/All_Images/51.jpg"),
    detail:
      "Northern Lights, Golden Circle, Myvatn, Hot Springs, Waterfalls & Glacier Lagoon",
    price: 374990,
    discount: 0,
    id: 11,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/52.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/53.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/54.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/55.jpg"),
    },
    description:
      "Get off the beaten path to catch the Northern Lights! Explore Iceland in 8 days on this incredible road trip on the Ring Road.Enjoy the city lights of Reykjavik before you head out into the countryside to see the Northern Lights. We take you out to sea to chase the aurora borealis, go whale watching and try your hand at sea angling.",
    features: "Popular",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "3 Day - Iceland Bucket List Tour",
    type: "Multi-Day Package",
    image: require("../assets/tours/multidaytour/All_Images/56.jpg"),
    detail:
      "Glacier Hike, Reynisfjara, The South Coast, Snæfellsnes & Golden Circle - Package Tour Without Accommodation",
    price: 52675,
    discount: 0,
    id: 12,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/57.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/58.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/59.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/60.jpg"),
    },
    description:
      "See the Golden Circle, geothermal bathing, the best waterfalls on the South Coast, Reynisfjara black beach, engage in glacier hike and the sights of Snæfellsnes in West Iceland.Gullfoss, Geysir, Þingvellir & bathing at the Secret Lagoon, hike on Solheimajokull Glacier, Seljalandsfoss and Skógafoss waterfalls, Reynisfjara black beach and the jewels of Snæfellsnes peninsula. Just too many wonderful places to list!",
    features: "",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "The Ultimate Iceland and Highlight Hitter - 8 Day Tour",
    type: "Multi-Day Tour",
    image: require("../assets/tours/multidaytour/All_Images/61.jpg"),
    detail: "Ring Road Tour - Snaefellsnes & the Central Highlands of Iceland",
    reviews: 1,
    price: 394990,
    discount: 424990,
    id: 13,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/62.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/63.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/64.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/65.webp"),
    },
    description:
      "In Iceland, you experience some of the most beautiful nature. Glistening glaciers, active volcanoes, powerful waterfalls, gushing geysers and colorful landscapes. Joining a guided 8-day tour in a small group will give you the opportunity to take your Iceland experience to the next level. The tour is available all year, meaning you can join us in summer as well as in winter. The activities on Day 8 of the tour change depending on the season. During the summer, you'll hop on a super jeep tour through the remote Icelandic Highlands. In winter, you'll have the chance to ride across Langjokull Glacier on a snowmobile! Thingvellir National Park is gorgeous and so are the tales of natural wonder waiting to be told. Skógafoss is breathtaking on its own, but have you heard of the troll that turned into stone when it stared at it? Why is Goðafoss called the waterfall of the gods? Get the full concept of why everyone loves Iceland this deeply. Go beyond!",
    features: "New Tour",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "2 Day Icelandic Adventure Package",
    type: "Multi-Day Package",
    image: require("../assets/tours/multidaytour/All_Images/66.jpg"),
    detail:
      "Lava Tunnel, Whale Watching, South Coast Highlights and Glacier Hike",
    price: 53023,
    discount: 0,
    id: 14,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/67.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/68.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/69.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/70.jpg"),
    },
    description:
      "This 2-Day Icelandic Adventure tour package combines some of the favorite activities. You’ll go lava caving and learn how these majestic lava tunnels are formed, then you’ll go whale watching with the hope to see some dolphins and whales in their natural habitat. The second day is dedicated to South Coast treasures and an unforgettable glacier hike. If you don’t want to miss out on anything, join this 2-day Icelandic Adventure Tour! On this 2-day adventure tour, you’ll dig deep into the lava tunnel, go whale watching, visit the highlights of the South Coast, and take up a glacier hike!",
    features: "",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
  {
    currency: "ISK",
    name: "6 Day Tour-Laugavegur & Fimmvörðuháls Trek",
    type: "Multi-Day Tour",
    image: require("../assets/tours/multidaytour/All_Images/71.webp"),
    detail: "Experience the Highlands of Iceland",
    reviews: 11,
    price: 349990,
    discount: 389990,
    id: 15,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/72.webp"),
      image2: require("../assets/tours/multidaytour/All_Images/73.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/74.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/75.webp"),
    },
    description:
      "Experience the highlands of Iceland on a 6-day trekking tour along the iconic Laugavegur trail. This trail is popular with hikers hoping for a real trekking experience in Iceland’s center. You will see the best Icelandic nature has to offer - glaciers, volcanoes, lava fields, geothermal hot springs and colorful mountains, a real mix of landscapes on your trek.Hiking the Laugavegur trail is a unique experience in Iceland, one that many people add to their bucket lists. Adding Fimmvörðuháls into the equation is absolute magic! The 55-kilometer-long Laugavegur trek connects Landmannalaugar Nature Reserve with Þórsmörk Valley. Among breathtaking views of volcanoes, hot springs and more, see the new craters from the 2010 eruption at Fimmvörðuháls. ",
    features: "Special Offers",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Pick-up & drop off from Reykjavík": true,
      "English speaking expert guide": true,
      "Northern Lights photos": true,

      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
  },
];

export const PrivateAirportTransferData = [
  {
    currency: "ISK",
    name: "From Keflavik Airport to Reykjavik Hotels",
    type: "Keflavik airport transfer",
    image:airportImage,
    reviews: 27,
    price: 19900,
    duration: 45,
    difficulty: "Easy",
    discount: 0,
    id: 1,
  },
  {
    currency: "ISK",
    name: "From Reykjavik to Keflavik Airport",
    type: "Keflavik airport transfer",
    image:airportImage,
    reviews: 27,
    price: 19900,
    duration: 45,
    difficulty: "Easy",
    discount: 0,
    id: 2,
  },
];

export const API = "http://localhost:8600";

export const PrivateTours = [
  {
    currency: "ISK",
    imageUrl: require("../assets/tours/multidaytour/All_Images/84.jpeg"),
    name: "Jokulsarlon Glacier Lagoon and Diamond Beach South Iceland",
    description:
      "Iceland’s South Coast boasts unparalleled natural beauty, with iconic attractions dotting its coastal landscape. Among these, Jokulsarlon Glacier Lagoon stands out as a breathtaking marvel, formed by the powerful forces of nature carving through the Vatnajokull ice cap. Adjacent to it lies the mesmerizing Diamond Beach, where icebergs, once part of the glacier tongue, adorn the black sands, creating a stunning contrast. Fjallsárlón Glacier Lagoon offers a quieter yet equally captivating experience, nestled within Vatnajökull National Park.",
    price: 180000,
    features: "Popular",
    highlights: [
      " Jökulsárlón Glacier Lagoon",
      "The Diamond Beach",
      "Jokulsarlon boat ride (optional extra)",
      " Fjallsárlón Lagoon",
      "Fjallsárlón Lagoon",
    ],
    included: {
      "Private transport": true,

      "English speaking expert guide": true,
      "Explore Hidden gems": true,
      "Air-conditioned vehicle": true,
      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
    discount: 0,
    id: 16,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/85.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/86.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/87.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/87.jpg"),
    },
    type: "Private Tour",
  },
  {
    currency: "ISK",
    imageUrl:
    require("../assets/tours/multidaytour/All_Images/86.jpg"),
    name: "Northern Lights Tour From Reykjavik",
    description:
      "Experience the mystical allure of Iceland's night sky on our Northern Lights tour departing from Reykjavik. As dusk falls, embark on a journey to witness the enchanting celestial dance of the Aurora Borealis, a mesmerizing display of vibrant colors painting the darkness. Led by expert guides, venture into the Icelandic wilderness, far from the city lights, to optimal viewing spots where the natural phenomenon unfolds in all its glory. Learn about the science and folklore behind the Northern Lights as you await their appearance, creating memories that will last a lifetime. With comfortable transport and knowledgeable commentary, our tour ensures an unforgettable experience under the magical Icelandic skies.",
    price: 98000,
    features: "Popular",
    highlights: [
      "Ride in a guided tour minibus",
      "See Iceland’s countryside",
      "Get free Northern Lights photos",
    ],
    included: {
      "Private transport": true,

      "English speaking expert guide": true,
      "Explore Hidden gems": true,
      "Air-conditioned vehicle": true,
      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
    discount: 0,
    id: 17,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/36.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/37.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/54.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/55.jpg"),
    },
    type: "Private Tour",
  },
  {
    currency: "ISK",
    imageUrl: require("../assets/tours/multidaytour/All_Images/75.jpg"),
    name: " Golden Circle and South Coast tour with 7+ Attractions",
    description:
      "Embark on a personalized journey through Iceland's Golden Circle and South Coast, immersing yourself in the country's rich natural wonders and cultural treasures. Begin your adventure in the charming village of Vik, where rugged landscapes meet the mesmerizing black sand beach, providing a picturesque backdrop for exploration. Marvel at the majestic Skógafoss and Seljalandsfoss waterfalls as they cascade down steep cliffs, creating an awe-inspiring spectacle of nature's power. Then, venture to the iconic Gullfoss Waterfall, where the Hvítá River plunges into a dramatic canyon, showcasing Iceland's raw beauty. Delve into the geothermal wonders of the Geysir Geothermal Area, where bubbling hot springs and erupting geysers captivate the senses. Conclude your journey at Thingvellir National Park, a UNESCO World Heritage Site, where the meeting of tectonic plates and rich historical significance await exploration. With a private tour tailored to your preferences, uncover the hidden gems and unforgettable experiences that await along Iceland's Golden Circle and South Coast.",
    price: 171000,
    features: "Popular",
    highlights: [
      "Vik village",
      "Black sand beach",
      "Skógafoss Waterfall",
      "Seljalandsfoss",
      "Gullfoss Waterfall",
      "Geysir Geothermal Area",
      "Thingvellir National Park",
    ],
    included: {
      "Private transport": true,

      "English speaking expert guide": true,
      "Explore Hidden gems": true,
      "Air-conditioned vehicle": true,
      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
    discount: 0,
    id: 18,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/47.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/1.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/4.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/75.jpg"),
    },
    type: "Private Tour",
  },
  {
    currency: "ISK",
    imageUrl: require("../assets/tours/multidaytour/All_Images/90.jpg"),
    name: "Snæfellsnes Peninsula with 6+ Attractions from Reykjavik",
    description:
      "Embark on an unforgettable adventure from Reykjavik to explore the enchanting Snæfellsnes Peninsula, a land of mesmerizing beauty and mystical charm. Immerse yourself in the rugged landscapes and dramatic coastline that define this captivating region. Begin your journey with a visit to Berserkjahraun, a lava field steeped in folklore and mystery. Marvel at the iconic silhouette of Kirkjufell Mountain, a symbol of Iceland's majestic landscapes, and its adjacent waterfall, Kirkjufellsfoss, whose cascading waters provide a picturesque backdrop. Discover the haunting beauty of Djúpalónssandur Beach, where black sands meet crashing waves, and the towering cliffs of Londrangar, remnants of ancient volcanic activity. Explore the picturesque fishing village of Arnarstapi, nestled amidst breathtaking natural scenery. With six awe-inspiring attractions to uncover, this journey promises an unforgettable exploration of the Snæfellsnes Peninsula's unparalleled beauty and intrigue.",
    price: 162000,
    features: "Popular",
    highlights: [
      "   Berserkjahraun",
      "Kirkjufell Mountain",
      "Kirkjufellsfoss, Grundarfjorour Iceland",
      "Djupalonssandur Beach, Utnesvegur, Hellnar Iceland",
      "Londrangar Basalt Cliffs, Hellnar 356 Iceland",
      "Arnarstapi, West Region",
    ],
    included: {
      "Private transport ": true,

      "English speaking expert guide": true,
      "Explore Hidden gems": true,
      "Air-conditioned vehicle": true,
      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
    discount: 0,
    id: 19,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/12.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/3.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/76.webp"),
      image4: require("../assets/tours/multidaytour/All_Images/15.jpg"),
    },
    type: "Private Tour",
  },
  {
    currency: "ISK",
    imageUrl: require("../assets/tours/multidaytour/All_Images/77.jpg"),
    name: "Golden Circle Tour with 5+ attractions from Reykjavik",
    description:
      "Embark on a personalized journey through Iceland's iconic Golden Circle, departing from Reykjavik, and indulge in a tailored experience designed to match your preferences. Discover the natural wonders and cultural treasures that define this renowned route, with over five handpicked attractions awaiting your exploration. Begin your adventure at Thingvellir National Park, a UNESCO World Heritage Site, where the meeting of tectonic plates and historical significance converge. Witness the awe-inspiring power of nature at the Geysir Geothermal Area, home to erupting geysers and bubbling hot springs. Marvel at the breathtaking cascade of Gullfoss Waterfall as it thunders into a dramatic canyon, showcasing Iceland's raw beauty. Delight your senses at the Laugarvatn geothermal bakery, where traditional Icelandic bread is baked underground using natural geothermal heat, offering a unique culinary experience. Then, journey to the enchanting Kerið Volcanic Crater Lake, a stunning natural marvel with vibrant turquoise waters surrounded by volcanic slopes.Conclude your Golden Circle adventure in Hveragerdi, a charming town known for its hot springs and geothermal wonders, where you can unwind and reflect on the unforgettable sights and experiences of the day. With a personalized tour tailored to your preferences, immerse yourself in the beauty and wonder of Iceland's Golden Circle like never before.",
    price: 141000,
    features: "Popular",
    highlights: [
      " Thingvellir National Park",
      " Geysir Geothermal Area",
      " Gullfoss Waterfall",
      "  Laugarvatn geothermal bakery",
      " Kerið Volcanic Crater Lake",
      " Hveragerdi",
    ],
    included: {
      "English speaking expert guide": true,
      "Explore Hidden gems": true,
      "Air-conditioned vehicle": true,
      "Free WiFi on the bus": true,
      "National Park entrance fee": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
    discount: 0,
    id: 20,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/47.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/1.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/4.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/75.jpg"),
    },
    type: "Private Tour",
  },
  {
    currency: "ISK",
    imageUrl: require("../assets/tours/multidaytour/All_Images/78.jpg"),
    name: "South Coast Day Tour with 6+ main attractions",
    description:
      " Experience the breathtaking beauty of Iceland's South Coast on an unforgettable day tour filled with natural wonders and awe-inspiring sights. Embark on a journey that promises to immerse you in the raw and untamed landscapes of this stunning region.Begin your adventure by marveling at the majestic Seljalandsfoss waterfall, where cascading waters plunge gracefully over the edge of a cliff, creating a mesmerizing spectacle. Continue your exploration at Skogafoss, a powerful waterfall that thunders into a pool below, surrounded by verdant cliffs and misty spray.Venture to the charming village of Vik, nestled amidst dramatic scenery and rugged coastline. Discover the black sand beaches of Reynisfjara, where towering basalt columns and crashing waves create a scene of unparalleled beauty.Journey further to witness the icy grandeur of Solheimajokull Glacier, where shimmering ice formations and crevasses beckon adventurers to explore. Finally, visit Dyrholaey, a natural archway that offers panoramic views of the surrounding landscape, providing the perfect finale to your South Coast adventure.With over six main attractions to discover, this day tour promises an unforgettable experience that will leave you inspired by the natural wonders of Iceland's South Coast.",

    features: "Popular",
    highlights: [
      "Seljalandsfoss, Hella Iceland",
      "Skogafoss, Skogar Iceland",
      "Vik, Vik, South Region",
      "Reynisfjara Beach, Route 215, Vik Iceland",
      "Solheimajokull Glacier, Vik Iceland",
      "Dyrholaey, Vik Iceland",
    ],
    included: {
      "English speaking expert guide": true,
      "Explore Hidden gems": true,
      "Air-conditioned vehicle": true,
      "Free WiFi on the bus": true,
    },
    what_to_bring: [
      "Hiking boots or very sturdy footwear",
      "Warm outdoor layers",
      "Weatherproof top layer",
      "Headwear",
      "Gloves",
      "Scarves",
      "Camera",
    ],
    tour_snapshot: {
      availability: "SEPT - APR",
      duration: "~4/5 HOURS",
      departs_from: "REYKJAVIK",
      level: "EASY",
      minimum_age: "6 YEARS",
      ratio: "1:28",
      meet_on_location: false,
    },
    discount: 0,
    id: 21,
    images: {
      image1: require("../assets/tours/multidaytour/All_Images/78.jpg"),
      image2: require("../assets/tours/multidaytour/All_Images/79.jpg"),
      image3: require("../assets/tours/multidaytour/All_Images/80.jpg"),
      image4: require("../assets/tours/multidaytour/All_Images/81.jpg"),
    },
    type: "Private Tour",
    price: 161000,
  },
];

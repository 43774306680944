import facebookimg from "../../assets/svgs/facebook.svg";
import instagramimg from "../../assets/svgs/instagram.svg";
import twitter from "../../assets/svgs/twitter.svg";
import { FooterAbout } from "./style";
import { Link } from "react-router-dom";
import footer from "../../assets/svgs/footer.svg"

export default function Footer() {
  return (
    <div className="bg-[#F7F8FA]">
      <div className="pt-[100px] lg:container px-5 lg:mx-auto">
        <FooterAbout className="flex flex-wrap pb-10">
          {/* <div className="w-full md:w-1/2 lg:w-1/4 mb-4 lg:mb-0">
            <h2 className="text-lg font-semibold pb-4">Support</h2>
            <div className="flex flex-col">
              <a href="/about#CustomerCareServices" className="text-footer">
                Help Center
              </a>
              <a href="/" className="text-footer">
                Our COVID-19 Response
              </a>
              <a href="/" className="text-footer">
                Cancellation options
              </a>
              <a href="/" className="text-footer">
                Safety information
              </a>
            </div>
          </div> */}
          <div className="w-full md:w-1/2 lg:w-1/4 mb-4 lg:mb-0">
            <h1 className="text-lg font-semibold pb-4">Company</h1>
            <div className="flex flex-col">
              {" "}
              {/* Changed here */}
              <Link to="/about" className="text-footer">
                About us
              </Link>
              <Link to="/contact" className="text-footer">
                Contact
              </Link>
              {/* <p className="text-footer">Community Blog</p> */}
              {/* <p className="text-footer">Careers</p> */}
              <Link to="/privacyPolicy" className="text-footer">
                Privacy policy
              </Link>
              <Link to="/privacyPolicy" className="text-footer">
                Terms of service
              </Link>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 mb-4 lg:mb-0">
            <h1 className="text-lg font-semibold pb-4">Contact</h1>
            <div className="flex flex-col">
              {" "}
              {/* Changed here */}
              {/* <a href="/" className="text-footer">
                Partnerships
              </a>
              <a href="/" className="text-footer">
                FAQ
              </a>
              <a href="/" className="text-footer">
                Cancellation options
              </a> */}
              <Link to="/contact" className="text-footer">
                Get in touch
              </Link>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4">
            <h1 className="text-lg font-semibold pb-4">Support</h1>
            <div className="flex">
              {" "}
              {/* No change needed here */}
              <a
                href="https://www.facebook.com/profile.php?id=61559333337751&viewas=&show_switched_toast=false&show_switched_tooltip=false&is_tour_dismissed=false&is_tour_completed=false&show_podcast_settings=false&show_community_review_changes=false&should_open_composer=false&badge_type=NEW_MEMBER&show_community_rollback_toast=false&show_community_rollback=false&show_follower_visibility_disclosure=false&bypass_exit_warning=true"
                 target="_blank" rel="noopener noreferrer"
              >
                <img className="w-12 mr-1" src={facebookimg} alt="Facebook" />
              </a>
              <a
                href="https://x.com/arctic_exp10337?t=bRGgRVH-0qrs9g0J526FGQ&s=09"
                 target="_blank"  rel="noopener noreferrer"
              >
                <img className="w-12 mr-1" src={twitter} alt="Twitter" />
              </a>
              <a
                href="https://www.instagram.com/arctic_explorer_adventures?igsh=am55OGQ3bTFtZjVl"
                 target="_blank" rel="noopener noreferrer"
              >
                <img className="w-12 mr-1" src={instagramimg} alt="Instagram" />
              </a>
              {/* <img className="w-12 mr-1" src={tiktok} alt="..." /> */}
            </div>
          </div>
        </FooterAbout>
        <div className="border-b-[1px] border-slate-400 border-solid"></div>
        <div className="flex flex-col lg:flex-row justify-between py-6">
          <p className="text-[#727272] mb-4 lg:mb-0">
            © Copyright Arctic Explorer Adventure {new Date().getFullYear()}
          </p>
          {/* <p className="text-[#727272] mb-4 lg:mb-0">developed by Spacelakh</p> */}
          <img
            src={footer}
            alt="Footer"
          />
        </div>
      </div>
    </div>
  );
}

import { useSelector } from "react-redux";
import BaseApp from "../../BaseComponent/BaseApp";
import Card from "../../component/card";
import "./style.css";
import { Button, Rate, Tooltip } from "antd";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import HelmetSEO from "../../component/SEO/Helmet";
import airportServiceImage from "../../assets/tours/multidaytour/All_Images/91.jpg"

const CardStyle = styled.div`
  .ant-btn:hover {
    background: #fd9832 !important;
    color: white !important;
    border: none;
  }
`;

export default function PrivateAirportTransfer() {
  const navigate = useNavigate();
  const PrivateAirportTransferData = useSelector(
    (state) => state.Products.PrivateAirportTransferData
  );

  return (
    <>
      <HelmetSEO
        title={
          "Private Airport Transfer in Iceland - Arctic Explorer Adventures"
        }
        content={
          "Book your private airport transfer with Arctic Explorer Adventures for a seamless and comfortable journey in Iceland. Enjoy personalized service and reliable transportation. Book now!"
        }
        keywords={
          "Private airport transfer Iceland, Iceland airport transfer, Reykjavik airport transfer, Keflavik airport transfer, private transfer Iceland, Arctic Explorer Adventures transfer, Iceland transportation service, airport pickup Iceland, private car service Iceland, Reykjavik airport taxi"
        }
      />
      <BaseApp>
        <div
          className="bg-airport_transfer   bg-cover"
          style={{
            backgroundPosition: "center center",
            height: "75vh",
            backgroundImage: `url(${airportServiceImage})`,
          }}
        >
          <div
            className=" flex justify-center items-center text-center h-full"
            style={{}}
          >
            <h1
              className="lg:text-[26px] lg:leading-26px text-[26px] leading-[26px] mb-3.5 font-bold text-white"
              style={{}}
            >
              Private Airport transfer Iceland
            </h1>
          </div>
        </div>

        <div className="at-content-container flex flex-wrap">
          <div className="w-full lg:w-1/2">
            <div className="at-element-widget-wrapper">
              <div className="at-content-inner">
                <h6 className="at-title">A Company with great service</h6>
                <div className="at-title-dec">
                  <br></br>
                  Are you flying into or out of Iceland? We offer a pleasant and
                  convenient airport transfer service. As we say, “Welcome!”.
                  Get everything you need for an unforgettable trip—we’ve fully
                  escorted tours and airport transfer services. Moreover, we
                  offer a variety of excursions, activities, and adventures in
                  Iceland, for customers who value money, time, and comfort. Our
                  driver will pick you up and drop you off at your hotel or
                  other accommodations, saving you time and effort waiting for
                  coaches. In addition to this, you can spare yourself the
                  hassle of lugging around luggage.
                </div>
              </div>
            </div>
            <div className="at-element-widget-wrapper">
              <div className="at-content-inner">
                <h6 className="at-title">Arrival</h6>
                <div className="at-title-dec">
                  <br></br>
                  This is the transfer from Keflavik airport to your hotel or
                  Airbnb location. The driver will be waiting for you at the
                  airport parking which is just 10 meter from arrival hall exit
                  gate. Our driver will track your flight from Flightradar and
                  will call you if you have mobile reception. Please call back
                  the driver when you pick up your luggage. We avoid arrival
                  hall because it’s too small and crowded.
                </div>
              </div>
            </div>
            <div className="at-element-widget-wrapper">
              <div className="at-content-inner">
                <h6 className="at-title">Departure</h6>
                <div className="at-title-dec">
                  <br></br>
                  This is the transfer from Hotel or Airbnb to Keflavik
                  International Airport. Our driver will pick you 3 hours prior
                  to your flight departure time. He will ring you upon arriving
                  to your pickup location.
                </div>
              </div>
            </div>
            <div className="at-element-widget-wrapper">
              <div className="at-content-inner">
                <h6 className="at-title-2">What’s Included?</h6>
                <div className="at-title-dec">
                  <span>
                    <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  Parking fees Drop off at the doorstep
                </div>
              </div>
            </div>
            <div className="at-element-widget-wrapper">
              <div className="at-content-inner">
                <h6 className="at-title-2">Cancellation Policy</h6>
                <div className="at-title-dec">
                  Cancellations must be made at least [3 days] before the
                  scheduled tour date to be eligible for a refund.
                  {/* <br></br>

                100% Free Reschedule in case of flight delays.*/}
                </div>
              </div>
            </div>

            <div className="at-element-widget-wrapper">
              <div className="at-content-inner">
                <h6 className="at-title-2">Customized Transfers?</h6>
                <div className="at-title-dec">
                  To get a quote please WhatsApp us at{" "}
                  <a
                    href="https://wa.me/3547629948"
                     target="_blank" rel="noopener noreferrer"
                    style={{ fontWeight: "bold", color: "green" }}
                  >
                    +354 762 9948
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="at-card-container grid grid-cols-1 gap-6 justify-center sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 p-2.5">
              {PrivateAirportTransferData &&
                PrivateAirportTransferData.map((data, index) => {
                  const {
                    name,
                    type,
                    price,
                    detail,
                    image,
                    id,
                    discount,
                    duration,
                    difficulty,
                    currency,
                  } = data;
                  return (
                    <CardStyle className="" key={index}>
                      <div className="w-full">
                        <div className="bg-white rounded-2xl shadow-custom">
                          <div
                            className="overflow-hidden rounded-t-2xl"
                            style={{ position: "relative" }}
                          >
                            <Link to={`/detail-tour/${id}`}>
                              {/* {discount != 0 && (
                        <div
                          style={{
                            width: "80px",
                            height: "30px",
                            position: "absolute",
                            top: "20px",
                            background: "#BB1213",
                            color: "white",
                            textAlign: "center",
                            zIndex: "1",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "0 25px 25px 0",
                          }}
                        >
                          <p style={{ fontWeight: "700" }}>
                            {discountPercentage.toFixed(0)} % Off
                          </p>
                        </div>
                      )} */}

                              {/* {features != "" && (
                        <div
                          style={{
                            width: "fit-content",
                            height: "30px",
                            position: "absolute",
                            top: "55px",
                            padding: "10px",
                            background: "#0D5382",
                            color: "white",
                            textAlign: "center",
                            zIndex: "1",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "0 25px 25px 0",
                          }}
                        >
                          <p style={{ fontWeight: "700" }}>{features}</p>
                        </div>
                      )} */}
                              <img
                                style={{
                                  height: "200px",
                                  backgroundPosition: "center center",
                                }}
                                className="w-full rounded-t-2xl hover:scale-110 transition-all duration-300 ease-in-out transform"
                                src={image}
                                alt="..."
                              />
                            </Link>
                          </div>
                          <div className="p-5">
                            <div>
                              <div className="mb-[5px]">
                                {/* <Rate
                  disabled
                  defaultValue={star}
                  count={star}
                  style={{
                    color: "#FA5636",
                  }}
                /> */}
                              </div>
                              <div className="mb-[5px]">
                                <Link
                                // to={`/detail-tour/${id}`}
                                >
                                  <Tooltip title={name}>
                                    <h2 className="hover:text-primary font-bold text-lg h-[40px] overflow-hidden whitespace-nowrap overflow-ellipsis">
                                      {name}
                                    </h2>
                                  </Tooltip>
                                </Link>
                              </div>
                              <p className="text-p">{type}</p>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  paddingTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    data-testid="time-icon"
                                    style={{ marginRight: "8px" }}
                                  >
                                    <path
                                      d="M7.17 8.33C7.45 8.33 7.67 8.1 7.67 7.83V5.5C7.67 5.22 7.89 5 8.17 5H8.5C8.77 5 9 5.22 9 5.5V9.17C9 9.44 8.77 9.67 8.5 9.67H5.5C5.22 9.67 5 9.44 5 9.17V8.83C5 8.56 5.22 8.33 5.5 8.33H7.17Z"
                                      fill="#57646F"
                                    ></path>
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M16 8C16 12.42 12.42 16 8 16C3.58 16 0 12.42 0 8C0 3.58 3.58 0 8 0C12.42 0 16 3.58 16 8ZM14 8C14 11.31 11.31 14 8 14C4.69 14 2 11.31 2 8C2 4.69 4.69 2 8 2C11.31 2 14 4.69 14 8Z"
                                      fill="#57646F"
                                    ></path>
                                  </svg>
                                  {duration} min
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                  }}
                                >
                                  <div class="difficultyIcon">
                                    <div
                                      color="#3ACC6C"
                                      fill="33%"
                                      class="icon"
                                    ></div>
                                  </div>
                                  {difficulty}
                                </div>
                              </div>
                            </div>
                            <div className="mt-[15px] pt-[15px] border-t border-solid border-[#dedede]">
                              <div
                                className="flex items-center"
                                style={{ justifyContent: "space-between" }}
                              >
                                <div>
                                  <Button onClick={() => navigate(`/contact`)}>
                                    Book
                                  </Button>
                                </div>
                                <div>
                                  {discount != 0 && (
                                    <span className="text-p mr-[5px]">
                                      <del>
                                        {currency}: {discount}
                                      </del>
                                    </span>
                                  )}
                                  <span className="font-bold mr-[5px]">
                                    {currency}. {price}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardStyle>
                  );
                })}
            </div>
          </div>
        </div>
      </BaseApp>
    </>
  );
}

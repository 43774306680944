import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./BaseComponent/ScrollToTop";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import Listing from "./pages/listing";
import DetailRoom from "./pages/detailRoom";
import Checkout from "./pages/checkout";
import NotFound from "./component/notFound/index.jsx";
import Cart from "./pages/cart";
import DetailHotel from "./pages/detailTour/index.jsx";
import SearchResult from "./pages/listing/Search.jsx";
import GoogleAnalytics from "./component/GoogleAnalytics.js";
// import { CanonicalLink } from './component/CanonicalLink';
import PrivateTour from "./component/private-tours/private-tour.jsx";
// import SelfDriveTour from "./component/private-tours/private-tour.jsx";
import PrivateAirportTransfer from "./pages/PrivateAirportTransfer/PrivateAirportTransfer.jsx";
import Blog from "./pages/Blog/blog.jsx";
import CoachRental from "./pages/coachRental/coachRental.jsx";
import PrivacyPolicy from "./pages/policy/privacyPolicy.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  setPrivateAirportTransferData,
  setPrivateTours,
  setdayTour,
  setmultiDayTour,
} from "./redux/slice/Products.js";
import {
  PrivateAirportTransferData,
  PrivateTours,
  dayTour,
  multiDayTour,
} from "./until/content.js";
import Custom404 from "./component/Custom404.js";

// function App() {
//   const dispatch = useDispatch();
//   const params = useSelector((state) => state.Products.params);
//   const exchangeRateISKtoUSD = 0.0076;
//   useEffect(() => {
//     if (params.currency === "USD") {
//       let updatedDayTour = dayTour.map((val) => {
//         const priceInUSD = (val.price * exchangeRateISKtoUSD).toFixed(2);
//         const discountInUSD = (val.discount * exchangeRateISKtoUSD).toFixed(2);
//         return {
//           ...val,
//           price: priceInUSD,
//           currency: "USD",
//           discount: discountInUSD,
//         };
//       });

//       let updatedMultiDayTour = multiDayTour.map((val) => {
//         const priceInUSD = (val.price * exchangeRateISKtoUSD).toFixed(2);
//         const discountInUSD = (val.discount * exchangeRateISKtoUSD).toFixed(2);
//         return {
//           ...val,
//           price: priceInUSD,
//           currency: "USD",
//           discount: discountInUSD,
//         };
//       });

//       let updatedPrivateAirportTransferData = PrivateAirportTransferData.map(
//         (val) => {
//           const priceInUSD = (val.price * exchangeRateISKtoUSD).toFixed(2);
//           const discountInUSD = (val.discount * exchangeRateISKtoUSD).toFixed(
//             2
//           );
//           return {
//             ...val,
//             price: priceInUSD,
//             currency: "USD",
//             discount: discountInUSD,
//           };
//         }
//       );

//       let updatedPrivateTours = PrivateTours.map((val) => {
//         const priceInUSD = (val.price * exchangeRateISKtoUSD).toFixed(2);
//         const discountInUSD = (val.discount * exchangeRateISKtoUSD).toFixed(2);
//         return {
//           ...val,
//           price: priceInUSD,
//           currency: "USD",
//           discount: discountInUSD,
//         };
//       });

//       dispatch(setdayTour(updatedDayTour));
//       dispatch(setmultiDayTour(updatedMultiDayTour));
//       dispatch(
//         setPrivateAirportTransferData(updatedPrivateAirportTransferData)
//       );
//       dispatch(setPrivateTours(updatedPrivateTours));
//     } else {
//       dispatch(setdayTour(dayTour));
//       dispatch(setmultiDayTour(multiDayTour));
//       dispatch(setPrivateAirportTransferData(PrivateAirportTransferData));
//       dispatch(setPrivateTours(PrivateTours));
//     }
//   }, [params]);

function App() {
  const dispatch = useDispatch();
  const params = useSelector((state) => state.Products.params);
  const exchangeRateISKtoUSD = 0.0076;
  // const canonicalUrl = 'https://arcticexploreradventure.com/';

  useEffect(() => {
    if (params.currency === "USD") {
      const convertToUSD = (val) => {
        const priceInISK = parseFloat(val.price.toString().replace(/,/g, ""));
        const discountInISK = parseFloat(
          val.discount.toString().replace(/,/g, "")
        );
        const priceInUSD = (priceInISK * exchangeRateISKtoUSD).toFixed(2);
        const discountInUSD = (discountInISK * exchangeRateISKtoUSD).toFixed(2);
        return {
          ...val,
          price: priceInUSD,
          currency: "USD",
          discount: discountInUSD,
        };
      };

      const updatedDayTour = dayTour.map(convertToUSD);
      const updatedMultiDayTour = multiDayTour.map(convertToUSD);
      const updatedPrivateAirportTransferData =
        PrivateAirportTransferData.map(convertToUSD);
      const updatedPrivateTours = PrivateTours.map(convertToUSD);

      dispatch(setdayTour(updatedDayTour));
      dispatch(setmultiDayTour(updatedMultiDayTour));
      dispatch(
        setPrivateAirportTransferData(updatedPrivateAirportTransferData)
      );
      dispatch(setPrivateTours(updatedPrivateTours));
    } else {
      dispatch(setdayTour(dayTour));
      dispatch(setmultiDayTour(multiDayTour));
      dispatch(setPrivateAirportTransferData(PrivateAirportTransferData));
      dispatch(setPrivateTours(PrivateTours));
    }
  }, [params, dispatch]);

  return (
    <div className="App">

     <GoogleAnalytics trackingId="G-GXFS3M6GCD" />


    {/* <CanonicalLink url={canonicalUrl} />  */}
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/dayTour" element={<Listing />} />
          {/* <Route path="/multidayTour" element={<MultiDayTour />} /> */}
          {/* <Route path="/selfDriveTours" element={<SelfDriveTour />} /> */}
          <Route path="/privateTours" element={<PrivateTour />} />
          <Route path="/detail-tour/:id/:name" element={<DetailHotel />} />
          <Route
            path="/detail-room/:nameHotel/:nameRoom"
            element={<DetailRoom />}
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/SerachResult/:name" element={<SearchResult />} />
          <Route
            path="/private-airport-transfer"
            element={<PrivateAirportTransfer />}
          />
          <Route path="/blog" element={<Blog />} />
          <Route path="/coachRental" element={<CoachRental />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route element={<NotFound />} />
          <Route component={Custom404} /> 
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

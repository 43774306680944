import React, { useState } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import ScrollUpButton from "../component/scrollUp";
import Anchor from "../component/anchor";

const BaseApp = ({ children }) => {
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  return (
    <div className="base-container ">
      <Header
        isHeaderFixed={isHeaderFixed}
        setIsHeaderFixed={setIsHeaderFixed}
      />
      <div id="Base" className="base-content-container">
        {children}
        <ScrollUpButton />
        <Anchor />
      </div>
      <Footer />
    </div>
  );
};

export default BaseApp;

import React from "react";
import "./private-tour.css";
import image1 from "../../assets/tours/multidaytour/All_Images/101.jpg";
import image2 from "../../assets/tours/multidaytour/All_Images/102.webp";

const PrivateTourAbout = () => {
  return (
    <section
      id="meetonlocationtours"
      className="meet-on-location-tours py-3 py-md-6 d-flex flex-column align-items-center justify-content-center"
      style={{ backgroundColor: "white", minHeight: "100vh" }}
    >
      <div className="container" id="about-self-drive">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-9 text-center">
            <div className="text-justify pt-1 meet-content">
              <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                 PRIVATE TOURS
              </h2>
              <p>
                At Arctic Adventures, we craft customizable personal tours in
                Iceland. Moments are to be enjoyed, at your own pace and in your
                own way. The Icelandic nature and culture are unique, and so are
                our travelers. One size does not fit all, and with our knowledge
                and ambition to awe you, we carefully tailor your tour to its
                attainable perfection. From hand-picked Icelandic restaurants
                and authentic local experiences to that one location that will
                simply take your breath away, we can create a private tour
                tailored to suit you.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="image-container-1 d-flex justify-content-center align-items-center">
        <div className="ratio-container ratio-w7h5">
          <div className="ratio-inner">
            <picture className="img-fluid nopin w-100">
              <source
                srcSet={image1}
                type="image/webp"
              />
              <source
                srcSet={image2}
                type="image/jpeg"
              />
              <img
                src={image2}
                className="img-fluid nopin w-100"
                alt="Why Choose Private Tour?"
                loading="lazy"
              />
            </picture>
          </div>
        </div>
        <div className="card-bg-shade"></div>
        <div className="category-cta__content-holder text-left align-items-end">
          <div className="category-cta__content"></div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-9 text-center">
            <div className="text-justify pt-1 meet-content">
              <div>
                <h2
                  style={{
                    fontSize: "25px",
                    marginTop: "20px",
                    fontWeight: "700",
                  }}
                >
                  Explore Iceland's Wonders
                </h2>
                <p>
                Embark on an unforgettable journey through Iceland's breathtaking landscapes with our curated selection of adventures.
                From glacier hikes to whale watching, volcano tours to Northern Lights excursions, each experience promises thrills and memories to last a lifetime. 
                Join us and discover the magic of Iceland with expert guides and unparalleled hospitality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="image-container-1 d-flex justify-content-center align-items-center">
        <div className="ratio-container ratio-w7h5">
          <div className="ratio-inner">
            <div style={{ marginTop: "20px" }}>
              <div className="mx-auto aa-w-70">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-12 col-lg-12 standard-content">
                      <h2
                        className="py-1 py-md-2 mb-md-0"
                        style={{
                          fontSize: "25px",
                          fontWeight: "700",
                          marginTop: "20px",
                        }}
                      >
                        PLANNING YOUR PRIVATE TOUR
                      </h2>
                      <div className="text-block">
                        <p style={{ textAlign: "justify" }}>
                          <span>
                          Choose private tours for a truly tailored travel experience, where every moment is crafted to match your interests, preferences, and pace. 
                          With the flexibility to customize your itinerary, access exclusive locations, and receive personalized attention from knowledgeable guides, 
                          private tours offer unparalleled comfort, convenience, and immersion in your destination. Whether you're seeking adventure, cultural exploration, 
                          or relaxation, a private tour ensures that your journey is as unique and unforgettable as you are.
                          </span>
                          <a href="/contact">
                            <span>get in touch</span>
                          </a>
                          <span>
                            &nbsp;with us today to start planning your extraordinary adventure in Iceland.&nbsp;
                          </span>
                        </p>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
   
  );
};

export default PrivateTourAbout;

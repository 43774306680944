import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  dayTour: [],
  multiDayTour: [],
  PrivateAirportTransferData: [],
  PrivateTours: [],
  params: {
    _page: 1,
    _limit: 12,
    currency: "ISK",
  },
};

export const ProductsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    getproductsSaga: () => {},
    setdayTour: (state, action) => {
      state.dayTour = action.payload;
    },
    setmultiDayTour: (state, action) => {
      state.multiDayTour = action.payload;
    },
    setPrivateAirportTransferData: (state, action) => {
      state.PrivateAirportTransferData = action.payload;
    },
    setPrivateTours: (state, action) => {
      state.PrivateTours = action.payload;
    },
    setParams: (state, action) => {
      state.params = action.payload;
    },
  },
});

export const getproductsSaga = createAction("getproductsSaga");

export const {
  setdayTour,
  setParams,
  setmultiDayTour,
  setPrivateAirportTransferData,
  setPrivateTours,
} = ProductsSlice.actions;

export default ProductsSlice.reducer;

import React from "react";
import { Helmet } from "react-helmet";

const HelmetSEO = ({ title, content, keywords }) => {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>{title}</title>
        <meta name="description" content={content} />
        <meta name="keywords" content={keywords} />
      </Helmet>
    </>
  );
};

export default HelmetSEO;

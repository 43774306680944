import { useEffect, useRef, useState } from "react";
import MenuSvg from "../../assets/svgs/menu.svg";
import ArrowLeftSvg from "../../assets/svgs/arrowLeft.svg";
import Logo from "../../assets/svgs/logo.svg";
import * as styled from "./style.js";
import { useClickOutside } from "../../until/clickOutside";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMobileAlt,
  faEnvelope,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faTwitter,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import { useDispatch } from "react-redux";
import { setParams } from "../../redux/slice/Products.js";
import { Tooltip } from "antd";
export default function Header({ isHeaderFixed, setIsHeaderFixed }) {
  const [isOpen, setIsOpen] = useState(false);
  const refMenu = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClickOutsideMenu = () => {
    setIsOpen(false);
  };

  useClickOutside(refMenu, handleClickOutsideMenu);

  const [dropdownOpen, setDropdownOpen] = useState(true);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div id="header" className={isHeaderFixed ? "header-fixed" : "relative"}>
      {isHeaderFixed ? null : (
        <div className="bg-[#232323]  hidden lg:flex text-[rgba(255,255,255,.8)] px-10 py-2 justify-between">
          <ul className="center">
            <li>
              <div className="flex items-center pr-5 border-r border-solid border-[rgba(255,255,255,.3)] text-[rgba(255,255,255,.8)]">
                <FontAwesomeIcon
                  className="w-4 mr-2"
                  color="white"
                  icon={faMobileAlt}
                />
                <a href="https://wa.me/3547629948" target="_blank">
                  +354 762 9948
                </a>
              </div>
            </li>
            <li>
              <a
                href="mailto:arcticexploreradventures@gmail.com"
                className="ml-4 flex items-center text-[rgba(255,255,255,.8)]"
              >
                <FontAwesomeIcon
                  className="w-4 mr-2"
                  color="white"
                  icon={faEnvelope}
                />
                <span className="font-weight-bold">
                  Mail us.
                </span>
              </a>
            </li>
          </ul>
          <ul className="center">
            <li>
              <Link
                className=" ml-4 flex items-center text-[rgba(255,255,255,.8)]"
                to="https://www.instagram.com/arctic_explorer_adventures?igsh=am55OGQ3bTFtZjVl"
                 target="_blank"  rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  className="w-4 mr-2"
                  color="white"
                  icon={faInstagram}
                />
              </Link>
            </li>
            <li>
              <Link
                className=" ml-4 flex items-center text-[rgba(255,255,255,.8)]"
                to="https://x.com/arctic_exp10337?t=bRGgRVH-0qrs9g0J526FGQ&s=09"
                 target="_blank" rel="noopener noreferrer"
              >
                {/* <img className="w-4 mr-2" src={EmailSvg} alt="..." /> */}
                <FontAwesomeIcon
                  className="w-4 mr-2"
                  color="white"
                  icon={faTwitter}
                />
              </Link>
            </li>
            <li>
              <Link
                className=" ml-4 flex items-center text-[rgba(255,255,255,.8)]"
                to="https://www.facebook.com/profile.php?id=61559333337751&viewas=&show_switched_toast=false&show_switched_tooltip=false&is_tour_dismissed=false&is_tour_completed=false&show_podcast_settings=false&show_community_review_changes=false&should_open_composer=false&badge_type=NEW_MEMBER&show_community_rollback_toast=false&show_community_rollback=false&show_follower_visibility_disclosure=false&bypass_exit_warning=true"
                 target="_blank" rel="noopener noreferrer"
              >
                {/* <img className="w-4 mr-2" src={EmailSvg} alt="..." /> */}
                <FontAwesomeIcon
                  className="w-4 mr-2"
                  color="white"
                  icon={faFacebook}
                />
              </Link>
            </li>
          </ul>
        </div>
      )}

      <div className="flex h-[72px] justify-between relative bg-white px-10 border-b border-solid border-[#EAEEF3] ">
        <div ref={refMenu} className="center">
          <div
            className="cursor-pointer py-7 pr-[60px] lg:hidden"
            onClick={() => setIsOpen(!isOpen)}
          >
            <img className="w-5 lg:block" src={MenuSvg} alt="..." />
          </div>

          <styled.DropdownMenu
            isOpen={isOpen}
            className="flex fixed h-screen z-[999] behavior-smooth overflow-auto top-0 min-w-[320px] bg-white flex-col"
          >
            <span
              onClick={() => setIsOpen(false)}
              className="!bg-[#f5f5f5] cursor-pointer px-5 py-4"
            >
              <img className="w-3" src={ArrowLeftSvg} alt="..." />
            </span>
            <ul className=" px-5">
              <li>
                <Link className="link-dropdown hover" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="link-dropdown hover" to="/dayTour">
                  Day Tour
                </Link>
              </li>
              <li>
                <Link className="link-dropdown hover" to="/privateTours">
                  Private Tour
                </Link>
              </li>
              <li>
                <Link
                  className="link-dropdown hover"
                  to="/private-airport-transfer"
                >
                  Private Airport Transfer
                </Link>
              </li>
              <li>
                <Link className="link-dropdown hover" to="/blog">
                  Blog
                </Link>
              </li>
              <li>
                <Link className="link-dropdown hover" to="/coachRental">
                  Coach Rental
                </Link>
              </li>
              {/* <li>
                <Link className="link-dropdown hover" to="/MultidayTour">
                  MULTIDAYTOUR
                </Link>
              </li> */}
              {/* <li>
                <Link className="link-dropdown hover" to="/about">
                  ABOUT
                </Link>
              </li> */}

              {/* <li>
                <span
                  onClick={() => handleDropdownMenuItem2()}
                  className="link-dropdown hover"
                >
                  HOTEL
                  <img className="w-4 ml-auto" src={DropdownSvg} alt="..." />
                </span>
                <styled.DropdownMenuItem
                  isDropdownMenuItem={isDropdownMenuItem.isHotel}
                  className="px-2.5 hidden"
                >
                  {hotels.map((hotel, index) => (
                    <li key={index} className="px-[30px]">
                      <Link
                        className="py-[15px] flex items-center font-medium hover"
                        to={`/detail-hotel/${hotel.nameHotel}`}
                      >
                        <img
                          className="w-12 h-12 mr-2"
                          src={hotel.images[0]}
                          alt=".."
                        />
                        {hotel.nameHotel}
                      </Link>
                    </li>
                  ))}
                </styled.DropdownMenuItem>
              </li> */}
              {/* <li>
                <Link
                  onClick={() => handleDropdownMenuItem3()}
                  className="link-dropdown hover"
                  to="/checkout"
                >
                  CHECKOUT
                </Link>
              </li> */}
              <li>
                <Link className="link-dropdown hover" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </styled.DropdownMenu>

          <div className="hidden md:block" href="#">
            <Link to="/">
              <img className="w-[70%] " src={Logo} alt="logo" />
            </Link>
          </div>
        </div>
        {isOpen && (
          <styled.Overlay
            isOpen={isOpen}
            className="fixed h-screen w-full bg-[rgba(0,0,0,.5)] z-[998] top-0 left-0 hidden"
          ></styled.Overlay>
        )}

        <div className="center">
          <Link className="block md:hidden" to="/">
            <img className="lg:w-full w-[200px] " src={Logo} alt="logo" />
          </Link>
          <ul className=" lg:flex hidden justify-between font-bold">
            <li>
              <Link className="flex  hover py-[35px] ps-2.5 pe-6" to="/">
                Home
              </Link>
            </li>
            <li>
              <Link className="block hover py-[35px] ps-2.5 pe-6" to="/dayTour">
                DayTour
              </Link>
            </li>

            <li>
              {/* <Link
                className="block hover py-[35px] ps-2.5 pe-6"
                to="/multidayTour"
              >
                MultiDayTour
              </Link> */}
            </li>
            <li>
              {/* <Link
                className="flex  hover py-[35px] ps-2.5 pe-6"
                to="/selfDriveTours"
              >
                Self Drive Tours
              </Link> */}
            </li>
            <li>
              <Link
                className="flex  hover py-[35px] ps-2.5 pe-6"
                to="/privateTours"
              >
                Private Tours
              </Link>
            </li>
            <li>
              <Link
                className="flex  hover py-[35px] ps-2.5 pe-6"
                to="/private-airport-transfer"
              >
                Private Airport Transfer
              </Link>
            </li>
            <li>
              <Link className="flex  hover py-[35px] ps-2.5 pe-6" to="/blog">
                Blog
              </Link>
            </li>
            <li>
              <Link
                className="flex  hover py-[35px] ps-2.5 pe-6"
                to="/coachRental"
              >
                Coach Rental
              </Link>
            </li>
            <li>
              <Link className="flex  hover py-[35px] ps-2.5 pe-6" to="/contact">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <div className="center">
          <ul className="center font-bold">
            <styled.Dropdown
              onClick={toggleDropdown}
              className="relative mr-5 lg:block "
            >
              <span className="flex hover py-[10px] ps-2.5" to="#">
                <Tooltip title="Currency" placement="top">
                  <FontAwesomeIcon
                    style={{ fontSize: "21px" }}
                    icon={faGlobe}
                  />
                </Tooltip>
              </span>
              <ul
                className={`dropdown ${
                  dropdownOpen ? "block" : "hidden"
                } !min-w-[100px]`}
                style={{ right: "-30px" }}
              >
                <li className="px-[30px]">
                  <div
                    onClick={() => {
                      {
                        let params = {
                          _page: 1,
                          _limit: 12,
                          currency: "ISK",
                        };
                        dispatch(setParams(params));
                      }
                    }}
                    className="py-[15px] cursor-pointer font-medium block hover"
                  >
                    ISK
                  </div>
                </li>
                <li className="px-[30px]">
                  <div
                    onClick={() => {
                      {
                        let params = {
                          _page: 1,
                          _limit: 12,
                          currency: "USD",
                        };
                        dispatch(setParams(params));
                      }
                    }}
                    className="py-[15px] cursor-pointer font-medium block hover"
                  >
                    USD
                  </div>
                </li>
              </ul>
            </styled.Dropdown>

            {/*         
            <li ref={refUser} className="md:ml-5">
              {"" ? (
                <>
                  <div
                    className="center rounded-[50%] w-[44px] h-[44px] md:shadow-custom md:border-line cursor-pointer"
                    onClick={() => handleDropdownMenuUser()}
                  >
                    <img className="w-5" src={UserSvg} alt="..." />
                  </div>
                  <styled.DropdownMenuItem
                    isDropdownMenuItem={isDropdownMenuItem.isUser}
                    className="absolute z-[999] py-2.5 min-w-[230px] top-[100%] bg-white border rounded-[12px] shadow-custom hidden right-0"
                  >
                    <li className="px-[30px]">
                      <p className="py-[15px] font-medium block hover">
                        User information
                      </p>
                    </li>
                    <li className="px-[30px]">
                      <button
                        className="py-[15px] font-medium block hover:text-red-500 cursor-pointer"
                        onClick={() => clearLocalLoginFC()}
                      >
                        Log out
                      </button>
                    </li>
                  </styled.DropdownMenuItem>
                </>
              ) : (
                <SignInOut />
              )}
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "./private-tour.css";
// import SelfDriveAbout from "./self-drive-about";
import PrivateTourAbout from "./private-tours-about";

const MeetOnLocationTours = () => {
  return (
    <section>
      <section style={{ backgroundColor: "#eff1f4" }}>
        <PrivateTourAbout />
      </section>
    </section>
  );
};

export default MeetOnLocationTours;

import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import * as styled from "./style";
import BaseApp from "../../BaseComponent/BaseApp";

export default function Cart(props) {
  return (
    <BaseApp>
      <div className="mb-10">
        <div className="py-5 border-bottom md:block hidden">
          <div className="lg:container lg:mx-auto px-5">
            <ul className="flex items-center justify-start">
              <li className="relative pr-5">
                <Link to="/" className="text-primary ">
                  Home
                </Link>
                <div className="absolute top-[50%] right-0 w-1 h-1 bg-[#EAEEF3] z-10"></div>
              </li>

              <li className="ml-5">
                <span className="text-gray">Your Cart</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="lg:container lg:mx-auto px-5">
          <div className="flex">
            <div className="lg:w-3/5 w-full shadow-custom rounded-2xl bg-white">
              <div className="p-5 overflow-hidden">
                <h2 className="text-2xl font-semibold pb-5">Another Choice</h2>
                <styled.SwiperCustom
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                  breakpoints={{
                    300: {
                      slidesPerView: 1,
                      spaceBetween: 24,
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 24,
                    },
                  }}
                ></styled.SwiperCustom>
              </div>
            </div>
            <div className="lg:w-2/5 w-full lg:mx-5">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387625.9023692689!2d-74.36563460807385!3d40.62149124937595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2zVGjDoG5oIHBo4buRIE5ldyBZb3JrLCBUaeG7g3UgYmFuZyBOZXcgWW9yaywgSG9hIEvhu7M!5e0!3m2!1svi!2s!4v1696335883699!5m2!1svi!2s"
                frameBorder="0"
                className="w-full h-full border-0 shadow-custom rounded-2xl bg-white"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </BaseApp>
  );
}

import { SwiperSlide } from "swiper/react";
import { Rate } from "antd";
import Card from "../../component/card/index.jsx";
import ButtonShare from "../../component/buttonShare";
import { Form } from "antd";
import { Link, useParams } from "react-router-dom";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import * as styled from "./style";
import BaseApp from "../../BaseComponent/BaseApp.js";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import Booking from "../../component/bookRoom/index.jsx";
import emailjs from "@emailjs/browser";
import { useSelector } from "react-redux";
import HelmetSEO from "../../component/SEO/Helmet.js";

export default function DetailHotel(props) {
  let { id } = useParams();
  let data = [];
  const dayTour = useSelector((state) => state.Products.dayTour);
  const multiDayTour = useSelector((state) => state.Products.multiDayTour);
  const PrivateTours = useSelector((state) => state.Products.PrivateTours);

  data.push(...multiDayTour, ...dayTour, ...PrivateTours);
  const hotelItem = data?.find((hotel) => hotel.id == id);
  const [isBookRoom, setIsBookRoom] = useState(false);
  const rateItems = [
    { name: "cleanliness", label: "Cleanliness" },
    { name: "communication", label: "Communication" },
    { name: "checkin", label: "Check-in" },
    { name: "accuracy", label: "Accuracy" },
    { name: "location", label: "Location" },
    { name: "value", label: "Value" },
  ];
  const [done, setDone] = useState(false);

  const formRef = useRef();

  const [price, setPrice] = useState(hotelItem.price);

  const [dates, setDates] = useState(new Date());
  const checkIn = moment(dates, "ddd MMM DD YYYY").format("MM/DD/YYYY");
  const checkOut = moment(dates, "ddd MMM DD YYYY").format("MM/DD/YYYY");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenReview, setIsOpenReview] = useState(false);
  const [numberAdults, setNumberAdults] = useState(0);
  const [rateValues, setRateValues] = useState({});
  const [numberChildren, setNumberChildren] = useState(0);

  const [isShowNavigation, setShowIsNavigation] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if ((numberAdults != 0) | (numberChildren != 0)) {
      let r = (numberAdults + numberChildren) * hotelItem.price;
      setPrice(r);
    }
  }, [numberAdults, numberChildren]);

  const tourType = hotelItem?.type;
  const destinationPath =
    tourType === "Multi-Day Tour"
      ? "/MultidayTour"
      : tourType === "Private Tour"
      ? "/privateTours"
      : "/dayTour";

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_uw9qde6",
        "template_nzah8cn",
        formRef.current,
        "arO0uEd5DnonNwYK1"
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setDone(true);
          formRef.current.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <>
      <HelmetSEO
        title={
          "Ultimate Northern Lights Tour in Iceland - Arctic Explorer Adventures"
        }
        content={
          "Join Arctic Explorer Adventures for the ultimate Northern Lights tour in Iceland. Experience the magic of the Aurora Borealis with our expert guides. Book now for an unforgettable adventure!"
        }
        keywords={
          "Northern Lights tour Iceland, Iceland Northern Lights tour, Aurora Borealis tour Iceland, Arctic Explorer Adventures, Iceland adventure tours, Northern Lights experience, best Northern Lights tour, Iceland night tours, Northern Lights excursion, Iceland guided tours"
        }
      />
      <BaseApp>
        <div className="relative">
          <div className="py-5 border-bottom hidden md:block">
            <div className="lg:container lg:mx-auto px-5">
              <ul className="flex items-center justify-start">
                <li className="relative pr-5">
                  <Link to="/" className="text-primary ">
                    Home
                  </Link>
                  <div className="absolute top-[50%] right-0 w-1 h-1 bg-[#EAEEF3] z-10"></div>
                </li>

                <li className="relative ml-5 pr-5">
                  <Link to={destinationPath} className="text-primary ">
                    <span>{hotelItem?.type}</span>
                  </Link>
                  <div className="absolute top-[50%] right-0 w-1 h-1 bg-[#EAEEF3] z-10"></div>
                </li>

                <li className="ml-5">
                  <span>{hotelItem?.name}</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="block px-5 h-[630px] mt-10">
            <Link
              className="md:w-1/3 w-full h-full md:inline-block block float-left md:pe-[5px]"
              to=""
            >
              <img
                className="w-full h-full md:rounded-bl-2xl md:rounded-tl-2xl md:rounded-br-none md:rounded-tr-none rounded-2xl object-cover"
                src={hotelItem?.images?.image1}
                alt="image hotel"
              />
            </Link>

            <Link
              className="w-1/3 h-[50%] px-[5px] pb-[5px] md:inline-block hidden float-left"
              to=""
            >
              <img
                className="w-full h-full"
                src={hotelItem?.images.image1}
                alt="image hotel 2"
              />
            </Link>

            <Link
              className="w-1/3 px-[5px] h-[50%] pb-[5px] md:inline-block hidden float-left"
              to=""
            >
              <img
                className="w-full h-full rounded-tr-2xl"
                src={hotelItem?.images.image2}
                alt="image hotel 3"
              />
            </Link>

            <Link
              to=""
              className="w-1/3 h-[50%] px-[5px] pt-[5px] md:inline-block hidden float-left"
            >
              <img
                className="w-full h-full "
                src={hotelItem?.images.image3}
                alt="image hotel 4"
              />
            </Link>

            <Link
              className="w-1/3 h-[50%] px-[5px] pt-[5px] md:inline-block hidden float-left"
              to=""
            >
              <img
                className="w-full h-full rounded-br-2xl"
                src={hotelItem?.images.image4}
                alt="image hotel 4"
              />
            </Link>
          </div>

          <div className="mt-12 lg:container lg:mx-auto px-5">
            <div className="flex mx-[-12px]">
              <div className="lg:w-2/3 w-full px-3">
                <div className="flex justify-between flex-wrap">
                  <div>
                    <div>
                      {/* <styled.RateCustom
                      disabled
                      defaultValue={hotelItem?.star}
                      count={hotelItem?.star}
                    /> */}
                    </div>
                    <h1 className="text-4xl font-bold">{hotelItem?.name}</h1>
                    {/* <div className="flex flex-wrap my-2">
                    <div className="text-primary font-bold border-solid border-[1px] border-primary pg-[#F9FBFF] rounded-md px-2 py-[1px]">
                      {hotelItem?.star} <span>/</span> 5
                    </div>
                    <span className="font-bold px-4">{hotelItem?.reviews}</span>
                    <p className="text-primary pr-5">(3 Reviews)</p>
                    <span className="text-gray">{hotelItem?.type}</span>
                  </div> */}
                  </div>
                  <div className="flex items-center">
                    <ButtonShare />
                    <Rate
                      character={
                        <span
                          role="img"
                          aria-label="heart"
                          style={{
                            fontSize: "24px",
                            padding: "15px",
                            borderRadius: "100%",
                          }}
                        >
                          ❤️
                        </span>
                      }
                      count={1}
                      className="center w-12 h-12 ml-3 border-line rounded-full shadow-custom hover:bg-[#d9303066]"
                    />
                  </div>
                </div>

                <hr className="my-10 text-slate-200"></hr>

                <div>
                  <h2 className="text-3xl font-semibold mb-10 underline">
                    About this {hotelItem?.name?.toLocaleLowerCase()}
                  </h2>
                  {/* {hotelItem?.description.map((item, index) => ( */}
                  <p className="text-base mb-5 leading-relaxed">
                    {hotelItem?.description}
                  </p>
                  <hr className="my-10 text-slate-200"></hr>

                  <div>
                    <h2 className="text-3xl font-semibold mb-10 underline">
                      Highlights
                    </h2>
                    <ul
                      className="list-disc text-base mb-5"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                      }}
                    >
                      {hotelItem?.highlights?.map((val, i) => (
                        <li key={i}>{val}</li>
                      ))}
                    </ul>

                    <hr className="my-10 text-slate-200"></hr>

                    <h2 className="text-3xl font-semibold mb-10 underline">
                      Included
                    </h2>
                    <ul
                      className="list-disc text-base mb-5"
                      style={{ paddingLeft: "40px" }}
                    >
                      {Object.entries(hotelItem?.included).map(
                        ([key, value], i) => (
                          <li key={i} className="text-base mb-5">
                            {key}
                          </li>
                        )
                      )}
                    </ul>

                    <hr className="my-10 text-slate-200"></hr>

                    <h2 className="text-3xl font-semibold mb-10 underline">
                      What to Bring
                    </h2>
                    <ul
                      className="list-disc text-base mb-5"
                      style={{ paddingLeft: "40px" }}
                    >
                      {hotelItem?.what_to_bring.map((val, i) => (
                        <li key={i} className="text-base mb-5">
                          {val}
                        </li>
                      ))}
                    </ul>

                    {/* <hr className="my-10 text-slate-200"></hr>

                  <h2 className="text-3xl font-semibold mb-10">
                    Tour Snapshot
                  </h2>
                  <u1
                    className="list-disc text-base mb-5"
                    style={{ paddingLeft: "40px" }}
                  >
                    {Object.entries(hotelItem?.tour_snapshot).map(
                      ([key, value], i) => (
                        <li key={i}>
                          {key}: {value}
                        </li>
                      )
                    )}
                  </u1> */}
                  </div>
                </div>
              </div>

              <div
                className={`${
                  isShowNavigation
                    ? "top-0 z-[30] opacity-100 overflow-scroll"
                    : ""
                } lg:relative fixed opacity-0 z-[-1] transition-all duration-500 lg:opacity-100 lg:z-30 bottom-0 left-0 pt-5 lg:pt-0 bg-white lg:w-1/3 w-full px-3 lg:!block`}
              >
                <div className="sticky top-0">
                  <div className="border-[1px] border-solid border-[#dedede] rounded-3xl p-8 shadow-xl drop-shadow-xl	">
                    <div className="flex justify-between items-center pt-2 group">
                      <button
                        onClick={() => setIsBookRoom(true)}
                        className={`${
                          isBookRoom ? "bg-primary text-white" : null
                        } bg-[#f7f8fa] hover:opacity-80 px-9 py-4 rounded-[30px] w-1/2 mr-2 text-center font-bold`}
                      >
                        Book
                      </button>
                      <button
                        onClick={() => setIsBookRoom(false)}
                        className={`${
                          !isBookRoom ? "bg-primary text-white" : null
                        } bg-[#f7f8fa] hover:opacity-80 px-9 py-4 rounded-[30px] w-1/2 ml-2 text-center font-bold`}
                      >
                        Inquiry
                      </button>
                    </div>

                    {isBookRoom ? (
                      <>
                        <div className="flex justify-between">
                          <p className="text-gray py-4">
                            From:
                            <span className="font-bold text-black">
                              {hotelItem.currency}. {price}
                            </span>
                          </p>
                          <div className="flex py-4">
                            <img className="w-4" src={""} alt="..." />
                            <span className="font-bold mx-2">
                              {hotelItem?.star}
                            </span>
                            <p className="text-gray">(3 reviews)</p>
                          </div>
                        </div>

                        <Booking
                          numberAdults={numberAdults}
                          numberChildren={numberChildren}
                          setNumberAdults={setNumberAdults}
                          setNumberChildren={setNumberChildren}
                          dates={dates}
                          setDates={setDates}
                          checkIn={checkIn}
                          checkOut={checkOut}
                        />

                        <button className="bg-primary hover:opacity-75 text-white p-[15px] rounded-[30px] w-full mr-2 text-center font-bold mt-8">
                          Submit Secure Payment
                        </button>
                      </>
                    ) : (
                      <form
                        className="py-[30px] px-[1px]"
                        ref={formRef}
                        onSubmit={sendEmail}
                      >
                        <h2 className="text-3xl font-bold mx-3">
                          Send a message
                        </h2>
                        <div className="flex mt-[10px]">
                          <styled.FormItemMessages
                            name="name"
                            label="Your Name"
                            rules={[
                              {
                                required: true,
                                message: "Name is required",
                              },
                            ]}
                            style={{
                              width: "50%",
                              padding: "0 12px",
                            }}
                          >
                            <input
                              type="text"
                              name="from_name"
                              className="user"
                              required="true"
                              style={{
                                width: "100%",
                                padding: "12px 15px",
                                boxShadow:
                                  "0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05)",
                                borderRadius: "12px",
                              }}
                              placeholder="Your Name*"
                            />
                          </styled.FormItemMessages>

                          <styled.FormItemMessages
                            name="phone"
                            label="Your Phone"
                            rules={[
                              {
                                required: true,
                                message: "Please input your phone number!",
                              },
                            ]}
                            style={{
                              width: "50%",
                              padding: "0 12px",
                            }}
                          >
                            <input
                              type="text"
                              name="phone_number"
                              required="true"
                              className="user"
                              style={{
                                width: "100%",
                                padding: "12px 15px",
                                boxShadow:
                                  "0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05)",
                                borderRadius: "12px",
                              }}
                              placeholder="Your Phone"
                            />
                          </styled.FormItemMessages>
                        </div>

                        <styled.FormItemMessages
                          name="email"
                          label="Email ID"
                          rules={[
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              required: true,
                              message: "Please input your E-mail!",
                            },
                          ]}
                          style={{
                            padding: "0 12px",
                          }}
                        >
                          <input
                            type="email"
                            required="true"
                            name="user_email"
                            className="user"
                            style={{
                              width: "100%",
                              padding: "12px 15px",
                              boxShadow:
                                "0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05)",
                              borderRadius: "12px",
                            }}
                            placeholder="Your Email*"
                          />
                        </styled.FormItemMessages>

                        <styled.FormItemMessages
                          name="content"
                          label="Message"
                          style={{
                            padding: "0 12px",
                          }}
                        >
                          <textarea
                            name="message"
                            className="user"
                            placeholder="Message"
                            rows={7}
                            style={{
                              boxShadow:
                                "0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05)",
                            }}
                          />
                        </styled.FormItemMessages>

                        <input
                          type="submit"
                          value="send"
                          className="button"
                          style={{
                            width: "100%",
                            height: "unset",
                            padding: "15px",
                            color: "#fff",
                            backgroundColor: "#3B71FE",
                            borderRadius: "50px",
                            fontWeight: "700",
                          }}
                        />
                        <span style={{ color: "green", textAlign: "center" }}>
                          {done && "Thanks for contacting me!"}
                        </span>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:container lg:mx-auto px-5 pt-[65px] pb-[30px] overflow-hidden">
            <div className="border-t border-solid border-[#dedede] mb-10"></div>
            <h2 className="title text-left">Recommended for you</h2>
            <styled.SwiperCustom
              slidesPerView={4}
              slidesPerGroup={4}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
              breakpoints={{
                300: {
                  slidesPerView: 1,
                  spaceBetween: 24,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 24,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 24,
                },
                992: {
                  slidesPerView: 4,
                  spaceBetween: 24,
                },
                1366: {
                  slidesPerView: 4,
                  spaceBetween: 24,
                },
              }}
            >
              {dayTour.slice(0, 8).map((item, index) => (
                <SwiperSlide key={index}>
                  <Card item={item} />
                </SwiperSlide>
              ))}
            </styled.SwiperCustom>
          </div>
        </div>
      </BaseApp>
    </>
  );
}

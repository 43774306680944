import React from "react";
import BaseApp from "../../BaseComponent/BaseApp";
import "./policy.css";
import { Button, Rate, Tooltip, Form, Input } from "antd";
import styled from "styled-components";
import HelmetSEO from "../../component/SEO/Helmet";
import privacy from "../../assets/tours/multidaytour/All_Images/92.jpg"

const PrivacyPolicy = () => {
  return (
    <>
      <HelmetSEO
        title={
          "Privacy Policy - Arctic Explorer Adventures | Protecting Your Privacy"
        }
        content={
          "Read Arctic Explorer Adventures' Privacy Policy to learn how we protect your personal information. Your privacy is important to us. Discover our practices and your rights."
        }
        keywords={
          "Privacy Policy, Arctic Explorer Adventures privacy, data protection, personal information security, privacy practices, user privacy, data privacy policy, Iceland tour company privacy, customer information security, online privacy"
        }
      />
      <BaseApp>
        <div className="coachRental">
          <div className="text-container">
            <h1 style={{ fontSize: "30px", fontWeight: "700" }}>
              PRIVACY POLICY
            </h1>
            <h2 style={{ fontSize: "17px", fontWeight: "700" }}>
              Your privacy matters. Review our policy for details
            </h2>
          </div>

          <div className="top-image">
            <img
              className="image-self"
              fetchpriority="high"
              alt="#"
              loading="eager"
              src={privacy}
            />
          </div>
          <div className="container" id="coach-rental-about">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 text-center">
                <div className="text-justify pt-1 meet-content">
                  <h1
                    style={{
                      fontSize: "25px",
                      fontWeight: "700",
                      marginTop: "50px",
                    }}
                  >
                    Discover the Arctic with us – Explore our privacy policy
                  </h1>
                  <br></br>
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Terms of Services:
                  </h2>
                  <p>
                    ✓By using our website and services, you agree to comply with
                    these terms and conditions.{" "}
                  </p>
                  <p>
                    ✓Not following them could lead to losing access. You must be
                    18 or older to booking or using our service, though parents
                    or guardians can book on behalf of minors.
                  </p>
                  <p>
                    {" "}
                    ✓Bookings are subject to availability and confirmation. We
                    reserve the right to cancel or modify bookings in the event
                    of unforeseen circumstances.
                  </p>
                  <p>
                    ✓Payment is required at the time of booking unless otherwise
                    specified. Additional fees may apply for changes or
                    cancellations to your reservation.
                  </p>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="about-self-drive">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 text-center">
                <div className="text-justify pt-1 meet-content">
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Privacy Policy:
                  </h2>
                  <br></br>
                  <p>
                    ✓We collect personal information, including name, email
                    address, and phone number, when you make a booking or sign
                    up for our newsletter. This information is used to process
                    bookings, communicate with you, and improve our services.
                  </p>
                  <p>
                    ✓Your data is stored securely and will not be shared with
                    third parties unless required by law or with your consent.
                  </p>
                  <p>
                    ✓Your data is stored securely and will not be shared with
                    third parties unless required by law or with your consent.
                  </p>
                  <p>
                    ✓You have the right to access, correct, or delete your
                    personal information at any time. Please contact us if you
                    have any questions or concerns about our privacy practices.
                  </p>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="about-self-drive">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 text-center">
                <div className="text-justify pt-1 meet-content">
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Customer Service:
                  </h2>
                  <br></br>
                  <p>
                    ✓We prioritize offering top-notch customer service to all
                    our guests.
                  </p>
                  <p>
                    ✓Our trained team members promptly and courteously assist
                    with any guest queries or concerns.
                  </p>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="about-self-drive">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 text-center">
                <div className="text-justify pt-1 meet-content">
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Safety and Security:
                  </h2>
                  <br></br>
                  <p>
                    ✓Ensuring the safety and security of our guests is our
                    utmost priority.
                  </p>
                  <p>
                    ✓We adhere to rigorous safety protocols and regulations to
                    maintain a safe and secure environment for everyone.
                  </p>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="about-self-drive">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 text-center">
                <div className="text-justify pt-1 meet-content">
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Environmental Sustainability:
                  </h2>
                  <br></br>
                  <p>
                    ✓We're dedicated to environmental sustainability, reducing
                    our impact on nature.
                  </p>
                  <p>
                    ✓Through eco-friendly practices, we encourage responsible
                    tourism.
                  </p>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="about-self-drive">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 text-center">
                <div className="text-justify pt-1 meet-content">
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Booking and Reservation Policies:
                  </h2>
                  <br></br>
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Availability:
                  </h2>
                  <br></br>
                  <p>
                    ✓All bookings and reservations are subject to availability.
                    We recommend booking in advance to secure your spot.
                  </p>
                  <br></br>
                  <br></br>
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Booking Confirmation:
                  </h2>
                  <br></br>
                  <p>
                    ✓To confirm a booking, guests may need to pay a deposit or
                    provide payment information. Your booking is considered
                    confirmed once payment is received.
                  </p>
                  <br></br>
                  <br></br>
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Group Bookings:
                  </h2>
                  <br></br>
                  <p>
                    ✓For group bookings or special events, please contact us
                    directly to check availability and discuss arrangements.
                  </p>
                  <br></br>
                  <br></br>
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Special Requests:
                  </h2>
                  <br></br>
                  <p>
                    ✓If you have any special requests or requirements, such as
                    dietary restrictions or accessibility needs, please inform
                    us at the time of booking.
                  </p>
                  <br></br>
                  <br></br>
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Changes and Modifications:
                  </h2>
                  <br></br>
                  <p>
                    ✓Changes to your booking, such as date or time
                    modifications, may be accommodated subject to availability.
                    Please contact us as soon as possible to request changes.
                  </p>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="about-self-drive">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 text-center">
                <div className="text-justify pt-1 meet-content">
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Cancellation Policy:
                  </h2>
                  <br></br>
                  <p>
                    ✓ Our cancellation policy varies depending on the activity
                    and timing. Please refer to our Cancellation and Refund
                    Policy for more information.
                  </p>
                  <br></br>
                  <br></br>
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Weather Conditions:
                  </h2>
                  <br></br>
                  <p>
                    ✓ In case of inclement weather or unforeseen circumstances,
                    we reserve the right to cancel or reschedule activities for
                    the safety and enjoyment of our guests.
                  </p>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="about-self-drive">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 text-center">
                <div className="text-justify pt-1 meet-content">
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Cancellation and Refund Policy:
                  </h2>
                  <br></br>
                  <p>
                    At Arctic explorer adventures , we maintain a firm stance on
                    cancellations and refunds to ensure operational efficiency
                    and consistency in service delivery. Please note the
                    following key points regarding our policy:
                  </p>
                  <br></br>
                  <br></br>
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Refunds:
                  </h2>
                  <br></br>
                  <p>
                    We do not offer refunds under any circumstances once a
                    booking has been confirmed.
                  </p>
                  <br></br>
                  <br></br>
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Booking Commitment:
                  </h2>
                  <br></br>
                  <p>
                    Upon booking confirmation, we proceed with renting vehicles
                    promptly, irrespective of any emergencies or delays
                    encountered by the customer.
                  </p>
                  <br></br>
                  <br></br>
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Cancellation Requirements:
                  </h2>
                  <br></br>
                  <p>
                    Should you need to cancel your booking, we require
                    notification at least one week in advance. However, it's
                    important to understand that even cancellations made within
                    this timeframe remain non-refundable. No Exceptions: Once a
                    booking is made, there are no options for refunds or
                    cancellations, regardless of the circumstances.
                  </p>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="about-self-drive">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 text-center">
                <div className="text-justify pt-1 meet-content">
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Payment Terms:
                  </h2>
                  <br></br>
                  <p>
                    ✓Payment for tours and activities must be made in full at
                    the time of booking, unless otherwise specified.
                  </p>
                  <br></br>
                  <br></br>
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Accepted payment methods include:
                  </h2>
                  <p>Credit Cards: Visa, Mastercard, American Express</p>
                  <p>Debit Cards: Visa Debit, Mastercard Debit</p>
                  <p>Mobile Payment Apps: Apple Pay, Google Pay</p>
                  <p>Digital Wallets: PayPal</p>
                  <p>Bank Transfers: Direct bank transfers</p>
                  <p>Cash Payments: Accepted in person</p>
                  <p>
                    Online Payment Gateways: Stripe, Square, PayPal Checkouts
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="about-self-drive">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 text-center">
                <div className="text-justify pt-1 meet-content">
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Changes to this Policy:
                  </h2>
                  <br></br>
                  <p>
                    ✓We reserve the right to update or modify this privacy
                    policy at any time, so please review it periodically.
                  </p>
                  <p>
                    ✓Changes to the policy will be posted on this page, with the
                    effective date indicated at the top of the page.
                  </p>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="about-self-drive">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 text-center">
                <div className="text-justify pt-1 meet-content">
                  <h2 style={{ fontSize: "25px", fontWeight: "700" }}>
                    Compliance and Legal Disclaimer:
                  </h2>
                  <br></br>
                  <p>
                    While we make every effort to provide accurate information,
                    we cannot guarantee the completeness or reliability of our
                    content. Please review our compliance and legal disclaimer
                    for important information.
                  </p>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseApp>
    </>
  );
};

export default PrivacyPolicy;

import { Link } from "react-router-dom";
import image from "../../assets/tours/multidaytour/All_Images/104.jpg"

export default function NotFound() {
  return (
    <div className="relative center w-full h-screen">
      <img
        className="w-full h-full"
        src={image}
        alt="404 image"
      />
      <Link
        to="/"
        className="block absolute bottom-[40px] text-white text-xl bg-primary px-10 py-3 rounded-3xl"
      >
        Home Pages
      </Link>
    </div>
  );
}

import { useEffect, useRef } from "react";
import ScrollUpSvg from "../../assets/svgs/scrollUp.svg";

export default function ScrollUpButton() {
  const myRef = useRef();

  const windowScrolled = () => {
    if (window.scrollY > 0) {
      myRef.current.classList.add("scroll-top-up");
    } else {
      myRef.current.classList.remove("scroll-top-up");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", windowScrolled);
    return () => {
      window.removeEventListener("scroll", windowScrolled);
    };
  }, []);

  const handleClick = (e) => {
    console.log("e: ", e);
    const headerElement = document.getElementById("Base");
    if (headerElement) {
      headerElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <a
      href="#Base"
      onClick={handleClick}
      ref={myRef}
      className="bg-[#FD9832] w-[42px] h-[42px] cursor-pointer bottom-[-40px] right-10 opacity-0 rounded-lg fixed z-[99] p-4 center"
    >
      <img className="w-6" src={ScrollUpSvg} alt="Scroll to top" />
    </a>
  );
}

import React from "react";
import BaseApp from "../../BaseComponent/BaseApp";
import "./coachRental.css";
import { Button, Rate, Tooltip, Form, Input } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import HelmetSEO from "../../component/SEO/Helmet";
import bus from "../../assets/tours/multidaytour/All_Images/94.jpg"

const CoachRental = () => {
  const navigate = useNavigate();
  return (
    <>
      <HelmetSEO
        title={
          "Experience Iceland in Comfort with Arctic Explorer Adventures' Premium Coach Rentals"
        }
        content={
          "Discover Iceland with Arctic Explorer Adventures' premium coach rentals. Enjoy comfortable, safe, and luxurious travel with personalized itineraries. Book your coach rental today!"
        }
        keywords={
          "Coach rental Iceland, Iceland coach hire, luxury coach rental Iceland, group travel Iceland, coach tours Iceland, Arctic Explorer Adventures, private coach rental Iceland, Iceland bus rental, premium coach hire Iceland, Iceland coach service, comfortable travel Iceland, Iceland group tours, corporate coach rental Iceland, Iceland transport services, Iceland travel"
        }
      />
      <BaseApp>
        <div className="coachRental">
          <div className="text-container">
            <h1 style={{ fontSize: "30px", fontWeight: "700" }}>
              COACH RENTAL
            </h1>
            <h2 style={{ fontSize: "17px", fontWeight: "700" }}>
              CUSTOMIZE YOUR'S FOR BETTER EXPERIENCE
            </h2>
          </div>

          <div
            className="top-image"
            style={{ height: "75vh", marginBottom: "75px" }}
          >
            <img
              className="image-self"
              style={{ height: "100%" }}
              fetchpriority="high"
              alt="#"
              loading="eager"
              src={bus}
            />
          </div>
          <div class="container mx-auto px-4" id="coach-rental-about">
            <div class="flex justify-center">
              <div class="w-full  text-center">
                <div class="text-justify pt-1 meet-content">
                  <h2 class="b-title text-3xl font-bold mb-4">
                    Welcome to ARCTIC EXPLORER ADVENTURES – Your Premier Coach
                    Rental Service
                  </h2>

                  <p class="b-title-dec text-lg mb-2">
                    Welcome to the enchanting world of Iceland, a land of
                    unparalleled natural beauty that has captured the hearts of
                    travelers worldwide. Iceland’s allure as a must-visit
                    destination is underpinned by an array of compelling
                    factors. Its unique natural beauty, featuring iconic
                    attractions such as majestic waterfalls like Gullfoss, the
                    ethereal landscapes of the Blue Lagoon, and the geothermal
                    wonders of Geysir Geothermal Area, promises awe-inspiring
                    experiences. Moreover, its geological marvels, shaped by the
                    Mid-Atlantic Ridge, offer opportunities to witness tectonic
                    plates meeting at Þingvellir National Park and explore
                    volcanic craters, lava fields, and glaciers. For those
                    seeking celestial wonders, Iceland’s Arctic Circle location
                    makes it a prime spot to witness the Northern Lights.
                    Adventure enthusiasts find their playground here, with
                    activities like glacier hiking, ice caving, snorkeling in
                    Silfra Fissure, and exploring lava caves. The vibrant
                    cultural scene, safety, accessibility via Keflavik
                    International Airport, and its status as a sought-after
                    cruise destination further elevate Iceland’s appeal. These
                    attributes, coupled with a commitment to sustainable tourism
                    and warm hospitality, underscore why Iceland is an
                    unmissable destination for those in search of a unique and
                    unforgettable journey. In this blog, we are thrilled to
                    share with you some helpful tips for cruise planners and
                    travel agents, on how to find the coach rental service in
                    Iceland.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="container mx-auto px-4" id="about-self-drive">
            <div class="flex justify-center">
              <div class="w-full  text-center">
                <div class="text-justify pt-1 meet-content">
                  <h2 class="b-title text-3xl font-bold mb-4">
                    Book Your Adventure Today
                  </h2>

                  <p class="b-title-dec text-lg mb-2">
                    Ready to explore the wonders of Iceland with ease and
                    comfort? Fill out the form below to book your coach, and
                    let’s set the wheels in motion for an unforgettable
                    adventure. Provide us with your trip details, and we’ll
                    tailor a travel experience that’s just right for you.
                  </p>

                  <h2 class="b-title-dec text-3xl font-bold mb-4">
                    You can call or Whatsapp us at{" "}
                    <a
                      href="https://wa.me/3547629948"
                       target="_blank" rel="noopener noreferrer"
                      class="text-green-500 font-semibold"
                    >
                      +354 762 9948
                    </a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex justify-center"
            style={{ margin: "20px 0 75px 0" }}
          >
            <a
              onClick={() => navigate("/contact")}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
            >
              Get in Touch
            </a>
          </div>
        </div>
      </BaseApp>
    </>
  );
};

export default CoachRental;

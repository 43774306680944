//import CardAbout from "../../component/cardAbout";
//import { Autoplay, Navigation, Pagination } from "swiper/modules";
//import * as styled from "./style";
//import { SwiperSlide } from "swiper/react";
import HelmetSEO from "../../component/SEO/Helmet";
import BaseApp from "../../BaseComponent/BaseApp";
import { useNavigate } from "react-router-dom";
import image1 from "../../assets/tours/multidaytour/All_Images/96.webp";
import image2 from "../../assets/tours/multidaytour/All_Images/97.jpg";
import image3 from "../../assets/tours/multidaytour/All_Images/98.webp";
import image4 from "../../assets/tours/multidaytour/All_Images/99.jpg";
import image5 from "../../assets/tours/multidaytour/All_Images/100.jpg";

export default function About(props) {
  const navigate = useNavigate();
  return (
    <>
      <HelmetSEO
        title={
          "About Us - Arctic Explorer Adventures | Your Iceland Travel Experts"
        }
        content={
          "Learn about Arctic Explorer Adventures, your trusted travel experts in Iceland. Discover our story, mission, and the team dedicated to making your Iceland adventure unforgettable."
        }
        keywords={
          "About Arctic Explorer Adventures, Iceland travel experts, Iceland tour company, Iceland adventure tours, Iceland travel agency, Arctic Explorer Adventures team, Iceland travel specialists, Iceland tourism, Iceland tour guides, Arctic Explorer Adventures story"
        }
      />
      <BaseApp>
        <div className="px-5">
          <img
            className="w-full"
            src={image1}
            alt="#video"
          />
        </div>
        <div className="pt-[50px]">
          <div className="pt-10 lg:container lg:mx-auto px-5">
            <div className="mb-10">
              <h1 className="text-3xl font-bold text-center mb-4">
                About Arctic Explorer Adventures
              </h1>
              <p
                className=" text-gray text-center"
                style={{ fontFamily: "Arial", fontSize: "16px" }}
              >
                Arctic Explorer Adventures is a premier travel and tourism
                company dedicated to providing exceptional experiences in one of
                the world's most captivating destinations. Founded with a
                passion for adventure and a deep love for Iceland's breathtaking
                landscapes, our company is committed to delivering unparalleled
                service, personalized itineraries, and unforgettable memories to
                travelers from around the globe.
              </p>
            </div>

            <div className="pt-[30px] pb-[60px]">
              <div className="flex flex-wrap">
                <div className="lg:w-1/2 w-full">
                  <div className="pr-[50px] mb-[30px]">
                    <div className="mb-6">
                      <h3 className="text-xl font-bold mb-4">Our Mission</h3>
                    </div>
                    <div className="mb-5">
                      <ul className="text-base text-gray">
                        <li
                          className="  text-gray"
                          style={{ fontFamily: "Arial", fontSize: "18px" }}
                        >
                          <span className="mission">
                            {" "}
                            Our mission is simple: to showcase the unique
                            beauty, rich culture, and incredible diversity of
                            Iceland while ensuring our guests have the trip of a
                            lifetime. We believe that travel is not just about
                            seeing new places; it's about creating meaningful
                            connections, fostering cultural understanding, and
                            leaving a positive impact on the destinations we
                            visit.
                          </span>
                        </li>
                        <li
                          className="py-[5px]  text-gray"
                          style={{ fontFamily: "Arial", fontSize: "18px" }}
                        >
                          <span className="mission">
                            As a locally owned and operated company, we have
                            deep roots in Iceland and a genuine passion for
                            sharing our homeland with the world. Our team
                            consists of experienced travel professionals who are
                            committed to providing personalized service, expert
                            guidance, and insider knowledge to help you make the
                            most of your Icelandic adventure.
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="px-5">
                      <img
                        className="w-full"
                        src={image2}
                        alt="Mission"
                      />
                    </div>
                    <div></div>
                  </div>
                </div>

                <div className="lg:w-1/2 w-full">
                  <div className="mb-6">
                    <h3 className="text-lg font-bold">Why choose us?</h3>
                  </div>
                  <div className="mb-5">
                    <ul className="text-base text-gray ">
                      <li className="py-3">
                        <span className="font-semibold">Local Expertise:</span>{" "}
                        <span className="text-base">
                          Our team consists of seasoned travelers and Iceland
                          locals who possess in-depth knowledge of the country's
                          hidden gems, ensuring that you'll experience Iceland
                          like never before.
                        </span>
                      </li>
                      <li className="py-3">
                        <span className="font-semibold">
                          Tailored Experiences:
                        </span>
                        <span className="text-base">
                          {" "}
                          We understand that every traveler is unique, which is
                          why we offer personalized itineraries and flexible
                          tour options. Whether you're a solo traveler, a
                          family, or a group of friends, we can tailor your
                          experience to match your interests, preferences, and
                          budget.
                        </span>
                      </li>
                      <li className="py-3">
                        <span className="font-semibold">
                          Exceptional Service:
                        </span>
                        <span className="text-base">
                          {" "}
                          From the moment you inquire about your trip to the
                          moment you return home, our dedicated team is
                          committed to providing unparalleled service and
                          support. We're here to answer your questions, address
                          your concerns, and ensure that your journey is
                          seamless and stress-free.
                        </span>
                      </li>
                      <li className="py-3">
                        <span className="font-semibold">Value for Money:</span>
                        <span className="text-base">
                          {" "}
                          We offer competitive pricing without compromising on
                          quality. With transparent pricing, no hidden fees, and
                          a range of accommodation and transportation options,
                          we ensure that you get the best value for your money.
                        </span>
                      </li>
                      <li className="py-3">
                        <span className="font-semibold">
                          Safety and Security:{" "}
                        </span>
                        <span className="text-base">
                          {" "}
                          Your safety is our top priority. We partner with
                          trusted vendors, adhere to strict safety standards,
                          and provide 24/7 emergency assistance to ensure that
                          you feel secure throughout your journey.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="p-[30px] pb-[60px]">
            <div className="flex flex-wrap">
              <div className="lg:w-1/2 w-full flex justify-center items-center">
                <img
                  className="w-full h-auto lg:max-w-xxl"
                  src={image3}
                  alt="#video"
                />
              </div>
              <div className="lg:w-1/2 w-full">
                <div className="pl-[50px] mb-[30px]">
                  <div className="mb-6">
                    <h3 className="text-xl font-bold text-center mb-4">
                      Our Services
                    </h3>
                    <div className="mb-5">
                      <ul className="text-base text-gray">
                        <li
                          className=" text-gray"
                          style={{ fontFamily: "Arial", fontSize: "18px" }}
                        >
                          <span className="mission">
                            {" "}
                            As a leading travel company dedicated to showcasing
                            the best of Iceland, we offer a range of services
                            designed to immerse you in the unique wonders of our
                            country. Explore the raw beauty of Iceland's
                            landscapes through our adventure tours, where expert
                            guides lead you on thrilling excursions across
                            glaciers, volcanoes, and waterfalls. Dive deep into
                            Icelandic culture with our immersive experiences,
                            offering insights into Viking heritage, folklore,
                            and traditions. Encounter the rich biodiversity of
                            our island nation on wildlife expeditions, from
                            whale watching to birdwatching in some of the most
                            pristine natural habitats. Elevate your journey with
                            our luxury escapes, featuring exclusive
                            accommodations, fine dining, and personalized
                            service against the backdrop of Iceland's stunning
                            scenery. And don't miss the chance to witness the
                            mesmerizing spectacle of the Northern Lights on our
                            dedicated expeditions, where you'll chase the aurora
                            across the Icelandic wilderness. With our commitment
                            to exceptional service, sustainability, and
                            showcasing the best of Iceland, we promise to make
                            your travel experiences truly unforgettable.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-[50px]">
            <div className="pt-10 lg:container lg:mx-auto px-5">
              <div className="mb-10">
                <h2 className="text-3xl font-bold text-center mb-4">
                  "Iceland's Eco-Tourism: A Journey of Sustainability"
                </h2>
                <p
                  className="text-base text-gray text-center"
                  style={{ fontFamily: "Arial", fontSize: "16px" }}
                >
                  Discover Iceland's commitment to sustainability and
                  eco-tourism woven into every facet of your journey. From the
                  moment you arrive, immerse yourself in a land where renewable
                  energy powers communities, eco-friendly practices preserve
                  pristine landscapes, and conservation efforts protect diverse
                  wildlife habitats. Explore sustainable travel options, from
                  eco-friendly accommodations to carbon-neutral transportation,
                  and engage in responsible tourism activities that support
                  local economies and empower communities. With Iceland's
                  dedication to preserving its natural treasures for future
                  generations, your visit becomes not just a journey, but a
                  meaningful contribution to the planet's well-being.
                </p>
              </div>
            </div>
          </div>

          <div className="px-5">
            <img
              className="w-full"
              src={image4}
              alt="#video"
            />
          </div>

          <div className="pt-[50px]">
            <div className="pt-10 lg:container lg:mx-auto px-5">
              <div className="mb-10">
                <h1 className="text-3xl font-bold text-center mb-4">
                  Discover Iceland: Where Adventure Awaits at Every Turn!
                </h1>
              </div>
              <div className="mb-5">
                <ul className="text-base text-gray">
                  <li className="py-3">
                    <span
                      class="text-base text-center  mb-4"
                      style={{ fontFamily: "'Roboto', sans-serif" }}
                    >
                      {" "}
                      Experience the Unforgettable: Journey to Iceland's Hidden
                      Gems!
                    </span>
                  </li>
                  <li className="py-3">
                    <span
                      class="text-base text-center mb-4"
                      style={{ fontFamily: "'Roboto', sans-serif" }}
                    >
                      {" "}
                      Embark on an extraordinary adventure to the land of fire
                      and ice, where every moment is a captivating exploration
                      waiting to unfold. Discover the raw, untouched beauty of
                      Iceland's rugged landscapes, where towering waterfalls
                      cascade into crystalline pools, and ancient glaciers carve
                      their majestic paths through the earth.
                    </span>
                  </li>
                  <li className="py-3">
                    <span
                      class="text-base text-center mb-4"
                      style={{ fontFamily: "'Roboto', sans-serif" }}
                    >
                      Indulge your senses in the warmth of Iceland's geothermal
                      springs, where the soothing waters invite you to relax and
                      rejuvenate amidst nature's embrace. Dive into the vibrant
                      culture of Reykjavik, where modern innovation meets
                      timeless tradition, and the spirit of adventure is
                      ever-present in every corner.{" "}
                    </span>
                  </li>
                  <li className="py-3">
                    <span
                      class="text-base text-center mb-4"
                      style={{ fontFamily: "'Roboto', sans-serif" }}
                    >
                      {" "}
                      Whether you're chasing the dancing lights of the Northern
                      Lights, embarking on a thrilling glacier trek, or simply
                      basking in the serenity of Iceland's untouched wilderness,
                      every moment in this enchanting land promises to be an
                      unforgettable experience.
                    </span>
                  </li>
                  <li className="py-3">
                    <span
                      class="text-base text-center mb-4"
                      style={{ fontFamily: "'Roboto', sans-serif" }}
                    >
                      {" "}
                      Join us on a journey of discovery and wonder, where the
                      magic of Iceland awaits at every turn. Your adventure
                      begins here!
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="CustomerCareServices" className="p-[30px] pb-[60px]">
            <div className="flex flex-wrap">
              <div className="lg:w-1/2 w-full">
                <div className="pr-[50px] mb-[30px]">
                  <div className="mb-6">
                    <h3 className="text-xl font-bold text-center mb-4">
                      We're Here To Help You Create Unforgettable Memories!!
                    </h3>
                  </div>
                  <div className="mb-5">
                    <ul className="text-base text-gray">
                      <li className="py-3">
                        <span
                          class=" text-center  mb-4"
                          style={{ fontFamily: "'Roboto', sans-serif" }}
                        >
                          {" "}
                          At Arctic Explorer Adventures - your satisfaction is
                          our top priority. Whether you're planning an epic
                          journey or have queries about your upcoming adventure,
                          our dedicated customer care team is here to lend a
                          helping hand. From destination suggestions to booking
                          assistance, we're committed to ensuring your travel
                          experience is smooth and seamless. Get in touch with
                          us today, and let's make your travel dreams a reality!
                        </span>
                      </li>
                      <li className="py-3">
                        <span
                          class=" text-center  mb-4"
                          style={{ fontFamily: "'Roboto', sans-serif" }}
                        >
                          {" "}
                          Happy Exploring!!!✈️😊
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="flex justify-center">
                    <a
                      onClick={() => navigate("/contact")}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                    >
                      Get in Touch
                    </a>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/2 w-full">
                <div className="px-5">
                  <img
                    className="w-full mt-6"
                    src={image5}
                    alt="#video"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseApp>
    </>
  );
}

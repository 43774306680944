// Custom404.js
import React from 'react';
import { Link } from 'react-router-dom';

const Custom404 = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <p>Here are some helpful links instead:</p>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About Us</Link></li>
        <li><Link to="/contact">Contact Us</Link></li>
      </ul>
      <p>If you believe this is an error, please <Link to="/contact">let us know</Link>.</p>
    </div>
  );
};

export default Custom404;

// import React from "react";
// import "./private-tour.css";

// const Card = ({
//   imageUrl,
//   title,
//   description,
//   availability,
//   duration,
//   departsFrom,
//   price,
// }) => {
//   return (
//     <div className="card">

//       <div className="image-container-card">
//         <img src={imageUrl} alt={title} />
//         <button className="self-drive-button">PRIVATE TOUR</button>
//       </div>
//       <div className="card-content">
//         <h2>{title}</h2>
//         <p>{description}</p>
//         <div className="details">
//           <div className="tour-details">
//             <p style={{ color: "rgb(59, 172, 241)" }}>AVAILABILITY</p>
//             <p style={{ color: "rgb(10, 90, 139)", fontWeight: "600" }}>
//               {availability}
//             </p>
//           </div>
//           <div className="tour-details">
//             <p style={{ color: "rgb(59, 172, 241)" }}>DURATION</p>
//             <p style={{ color: "rgb(10, 90, 139)", fontWeight: "600" }}>
//               {duration}
//             </p>
//           </div>
//           <div className="tour-details">
//             <p style={{ color: "rgb(59, 172, 241)" }}>DEPARTS FROM</p>
//             <p style={{ color: "rgb(10, 90, 139)", fontWeight: "600" }}>
//               {departsFrom}
//             </p>
//           </div>
//         </div>
//         <div className="card-footer">
//           <button>View Tour</button>
//           <p className="price">{price}</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Card;

import React from "react";
import "./private-tour.css";
import { useNavigate } from "react-router-dom";
import { Button, Rate, Tooltip } from "antd";
import GroupIcon from '@mui/icons-material/Group';


const Card = ({ imageUrl, title, description, price, id, currency }) => {
  const navigate = useNavigate();
  return (
    <div
      className="card"
      onClick={() => navigate(`/detail-tour/${id}/${title}`)}
      style={{
        height: "450px",
      }}
    >
      {/* <div className="image-container-card">
        <img src={imageUrl} alt={title} />
      </div> */}
      <div className="h-60 overflow-hidden">
        <img src={imageUrl} alt={title} className="w-full h-full object-fill" />
      </div>
      <div className="card-content">
        <h1>{title}</h1>
        <p>{description}</p>
        <div className="participation"><GroupIcon/> 7</div>
        <div className="card-footer">
          {/* <Button
                    // onClick={() => navigate(`/detail-tour/${id}/${name}`)}
                  >
                    View Tour
                  </Button> */}
                 
          <button>View Tour</button>
          <p className="price">
            {currency}. {price}
          </p>
        </div>
      </div>
    </div>

    //   <div
    //   className="bg-white rounded-lg overflow-hidden shadow-md cursor-pointer"
    //   onClick={() => navigate(`/detail-tour/${id}/${title}`)}
    //   style={{ height: "450px" }}
    // >
    //   <div className="h-60 overflow-hidden">
    //     <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
    //   </div>
    //   <div className="p-4">
    //     <h2 className="text-lg font-semibold">{title}</h2>
    //     <p className="text-sm">{description}</p>
    //     <div className="flex justify-between items-center mt-4">
    //       <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
    //         View Tour
    //       </button>
    //       <p className="text-lg font-semibold">ISK. {price}</p>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Card;

import { useState, useRef, useEffect } from "react";
import Card from "../../component/card";
import filter from "../../assets/svgs/filter.svg";
import CloseSvg from "../../assets/svgs/close.svg";
import * as styled from "./style.js";
import { multiDayTour } from "../../until/content.js";
import HelmetSEO from "../../component/SEO/Helmet.js";
import BaseApp from "../../BaseComponent/BaseApp.js";
import { Dropdown, Radio } from "antd";
import DropDownSvg from "../../assets/svgs/dropdown.svg";
import list_category from "../../assets/svgs/list_category.svg";
import list_menu from "../../assets/svgs/list_menu.svg";
import { useSelector } from "react-redux";

export default function Listing(props) {
  const [showFilter, setShowFilter] = useState(false);
  const topRef = useRef();
  const products = useSelector((state) => state.Products.dayTour);

  const [showData, setShowData] = useState(products);

  useEffect(() => {
    setShowData(products);
  }, [products]);

  const [isOpen, setIsOpen] = useState({
    isFilter: true,
    isReviewScore: true,
    isHotelStar: true,
    isFacilities: true,
    isHotelTheme: true,
  });

  const handleChangePage = (page) => {};

  const onFinishPrice = (Prices) => {};

  const onChangeReview = (value) => {};

  const onChangeStar = (value) => {};

  const onChangeFacilities = (value) => {};

  const onChangeHotelTheme = (value) => {};

  const onChangeRadioSort = (e) => {
    if (e.target.value == "New Tour") {
      let r = products?.filter((val) => val.features == "New Tour");
      setShowData(r);
    } else if (e.target.value == "asc") {
      let r = products.slice().sort((a, b) => a.price - b.price);
      setShowData(r);
    } else if (e.target.value == "desc") {
      let r = products.slice().sort((a, b) => b.price - a.price);
      setShowData(r);
    }
  };

  const handleDropdown = (item) => {
    setIsOpen(() => ({
      ...isOpen,
      [item]: !isOpen[item],
    }));
  };

  const reviewOptions = [
    "Excellent",
    "Very Good",
    "Average",
    "Poor",
    "Terrible",
  ];

  const starOptions = [
    {
      label: <styled.RateCustom defaultValue={5} count={5} disabled />,
      value: 5,
    },
    {
      label: <styled.RateCustom defaultValue={4} count={4} disabled />,
      value: 4,
    },
    {
      label: <styled.RateCustom defaultValue={3} count={3} disabled />,
      value: 3,
    },
    {
      label: <styled.RateCustom defaultValue={2} count={2} disabled />,
      value: 2,
    },
    {
      label: <styled.RateCustom defaultValue={1} count={1} disabled />,
      value: 1,
    },
  ];

  const facilitiesOption = [
    "Air Conditioning",
    "AirPort Transport",
    "Fitness Center",
    "Flat Tv",
    "Heater",
    "Internet - Wifi",
    "Parking",
    "Pool",
    "Restaurant",
    "Smoking Room",
    "Spa & Sauna",
    "Washer & Dryer",
  ];

  const hotelThemeOption = [
    "Best value",
    "Boutique",
    "Budget",
    "Business",
    "Charming",
    "Classic",
    "Green",
    "Luxury",
    "Mid-range",
    "Party",
    "Quaint",
    "Quite",
    "Romantic",
    "Standard",
    "Trendy",
  ];

  const handleReset = () => {
    setShowData(products);
  };

  let r = multiDayTour.map((val) => {
    return {
      ...val,
      highlights: [
        "Ride in a guided tour minibus",
        "See Iceland’s countryside",
        "Get free Northern Lights photos",
      ],
      included: {
        "Pick-up & drop off from Reykjavík": true,
        "English speaking expert guide": true,
        "Northern Lights photos": true,
        "Icelandic hot cocoa, chocolate & blanket": true,
        "Free WiFi on the bus": true,
        "Complementary retry valid for 3 years when no Northern Lights are seen on the tour": true,
      },
      what_to_bring: [
        "Hiking boots or very sturdy footwear",
        "Warm outdoor layers",
        "Weatherproof top layer",
        "Headwear",
        "Gloves",
        "Scarves",
        "Camera",
      ],
      tour_snapshot: {
        availability: "SEPT - APR",
        duration: "~4/5 HOURS",
        departs_from: "REYKJAVIK",
        level: "EASY",
        minimum_age: "6 YEARS",
        ratio: "1:28",
        meet_on_location: false,
      },
    };
  });
  console.log("r: ", r);

  return (
    <>
      <HelmetSEO
        title={
          "Explore the Wonders of Iceland in a Single Day - Arctic Explorer Adventure"
        }
        content={
          "Embark on an unforgettable adventure with our single-day tour of Iceland. Discover breathtaking landscapes, majestic waterfalls, and more. Book now!"
        }
        keywords={
          "norwegian travel tromso,best time and place to see northern lights,northern lights packages,tromso travel,norwegian travel company,norway tours for seniors,norway guided tours,arctic explorers tromso northern lights,tromso aurora borealis tour,tromso local tour,northern lights norway tour package,to do in tromso,travel to tromso norway,best norway tours,best time to see northern lights in tromso,best norway northern lights tour,best way to see northern lights,trips to see the northern lights in norway,tromso trips northern lights,skjervøy whale watching,tromso best northern lights tour,arctic expedition tromso,aurora borealis trips,see the northern lights in norway,northern lights travel packages,cheap northern lights holidays,guide tromso,best time to visit norway for northern lights,get your guide tromso northern lights,trips from tromso,best place to stay in tromso for northern lights,tour northern lights tromso,best place in norway to see northern lights,whale watching tromso norway,tromso travel guide,best tours of norway,aurora borealis tour tromso,tromso adventures,best arctic holidays,tromso safari tours,norway lights,norway travel packages,tromso guided tours,best time to go to norway for northern lights,tromsø northern lights holiday,norway vacations packages,northern lights boat tour tromso,tromso northern lights boat tour,best northern lights tour tromso norway,best place for northern lights,northern lights where to see,best time of year to see northern lights,arctic adventures northern lights,whale watching tromso best time,northern lights tromso tour package,best tromso tours"
        }
      />
      <BaseApp>
        <div className="overflow-hidden">
          <div className="bg-daytour h-[460px] lg:h-full  bg-no-repeat bg-cover">
            <div className="lg:py-[90px] py-[35px] text-center">
              <div className="lg:container lg:mx-auto px-5 relative">
                {/* <Search locationHotel={locationHotel} /> */}
              </div>
            </div>
          </div>

          <div ref={topRef} className="lg:container lg:mx-auto px-5 mb-[60px]">
            <div className="flex justify-center lg:pt-[70px] pt-[46px]">
              <div
                className={`${
                  showFilter
                    ? "z-[9998] p-[30px] opacity-100 overflow-scroll"
                    : ""
                } fixed lg:relative bg-white bottom-0 top-0 z-[-1] lg:z-0 lg:block opacity-0 lg:opacity-100 lg:h-auto w-full transition-all duration-500 lg:w-1/4`}
              >
                <button className="p-2 mb-2 lg:hidden">
                  <img
                    onClick={() => setShowFilter(false)}
                    className="w-5"
                    src={CloseSvg}
                    alt="close"
                  />
                </button>
                <div className="relative hidden lg:block rounded-[20px] mb-[30px]">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3482284.9352637897!2d-24.521585544923767!3d64.77535901847581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48d22b52a3eb6043%3A0x6f8a0434e5c1459a!2sIceland!5e0!3m2!1sen!2sin!4v1711114176458!5m2!1sen!2sin"
                    style={{ border: 0, borderRadius: "20px" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                  <a
                     target="_blank" rel="noopener noreferrer"
                    href="https://maps.app.goo.gl/kzJbTqeiYQDZQNan9"
                    className="absolute bottom-2 right-[18%] cursor-pointer bg-white text-primary border-2 border-solid border-stone-300 mx-[10%] py-3 px-4 font-semibold rounded-3xl"
                  >
                    View in a map
                  </a>
                </div>

                {/* <div className="py-7 bg-filter mb-[30px] rounded-[20px]">
                <div
                  className="flex px-5 justify-between"
                  onClick={() => handleDropdown("isFilter")}
                >
                  <div className="border-none font-bold text-lg">
                    Filter Price
                  </div>
                  <div className="cursor-pointer">
                    <FontAwesomeIcon
                      icon={isOpen.isFilter ? faChevronUp : faChevronDown}
                    />
                  </div>
                </div>
                <div
                  className={`max-h-0 px-5 overflow-hidden transition-all duration-300 ${
                    isOpen.isFilter ? "max-h-screen" : ""
                  }`}
                >
                  <Form
                    form={formPrice}
                    onFinish={onFinishPrice}
                    className="bg-filter mt-5 rounded-xl"
                  >
                    <Form.Item name="priceRange">
                      <Slider
                        range={{
                          draggableTrack: true,
                        }}
                        defaultValue={[100, isPrice]}
                        min={100}
                        max={254}
                        onChange={(value) => handleChangePrice(value)}
                      />
                    </Form.Item>

                    <div className="flex justify-between mb-5">
                      <div className="mr-7 w-1/2 py-2 px-3 bg-white border-[#dedede] border-solid border rounded-2xl">
                        <h2 className="text-gray">Min price</h2>
                        <p>
                          ISK.<span>10000</span>
                        </p>
                      </div>
                      <div className="w-1/2 p-2 py-2 px-3 bg-white border-[#dedede] border-solid border rounded-2xl">
                        <h2 className="text-gray">Max price</h2>
                        <p>
                          ISK.<span>{isPrice}</span>
                        </p>
                      </div>
                    </div>
                    <hr className="text-slate-300"></hr>
                    <div className="flex justify-between mt-5">
                      <p
                        onClick={() => {
                          formPrice.resetFields();
                          setIsPrice(254);
                        }}
                        className="text-primary border-b-[1px] border-solid font-medium pt-2 cursor-pointer"
                      >
                        Clear
                      </p>
                      <Form.Item className="mb-0">
                        <styled.ButtonCustom type="primary" htmlType="submit">
                          Apply
                        </styled.ButtonCustom>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>

              <div className="px-5 py-7 bg-filter mb-[30px] rounded-[20px]">
                <div
                  className="flex justify-between"
                  onClick={() => handleDropdown("isReviewScore")}
                >
                  <div className="border-none font-bold text-lg">
                    Review Score
                  </div>
                  <div className=" cursor-pointer">
                    <FontAwesomeIcon
                      icon={isOpen.isReviewScore ? faChevronUp : faChevronDown}
                    />
                  </div>
                </div>

                <div
                  className={`max-h-0 overflow-hidden transition-all duration-300 ${
                    isOpen.isReviewScore ? "max-h-screen" : ""
                  }`}
                >
                  <Form form={formReview} className="mt-5">
                    <Form.Item name="review">
                      <styled.CheckboxGroup
                        options={reviewOptions}
                        onChange={onChangeReview}
                      />
                    </Form.Item>
                  </Form>
                </div>
              </div>

              <div className="px-5 py-7 bg-filter mb-[30px] rounded-[20px]">
                <div
                  className="flex justify-between"
                  onClick={() => handleDropdown("isHotelStar")}
                >
                  <div className="border-none font-bold text-lg">Tour Star</div>
                  <div className="cursor-pointer">
                    <FontAwesomeIcon
                      icon={isOpen.isHotelStar ? faChevronUp : faChevronDown}
                    />
                  </div>
                </div>
                <div
                  className={`max-h-0 overflow-hidden transition-all duration-300 ${
                    isOpen.isHotelStar ? "max-h-screen" : ""
                  }`}
                >
                  <Form form={formStar} className="mt-5">
                    <Form.Item name="star">
                      <styled.CheckboxGroup
                        options={starOptions}
                        onChange={onChangeStar}
                      />
                    </Form.Item>
                  </Form>
                </div>
              </div>

              <div className="px-5 py-7 bg-filter mb-[30px] rounded-[20px]">
                <div
                  className="flex justify-between"
                  onClick={() => handleDropdown("isFacilities")}
                >
                  <div className="border-none font-bold text-lg">
                    Facilities
                  </div>
                  <div className="cursor-pointer">
                    <FontAwesomeIcon
                      icon={isOpen.isFacilities ? faChevronUp : faChevronDown}
                    />
                  </div>
                </div>
                <div
                  className={`max-h-0 overflow-hidden transition-all duration-300 ${
                    isOpen.isFacilities ? "max-h-screen" : ""
                  }`}
                >
                  <Form form={formFacilities} className="mt-5">
                    <Form.Item name="facilities">
                      <styled.CheckboxGroup
                        options={facilitiesOption.slice(0, isFacilitiesItem)}
                        onChange={onChangeFacilities}
                      />
                    </Form.Item>

                    {isFacilitiesItem < facilitiesOption.length && (
                      <div
                        onClick={() =>
                          setIsFacilitiesItem(isFacilitiesItem + 3)
                        }
                        className="text-primary cursor-pointer inline-flex justify-start border-b-[1px] border-b-primary border-solid "
                      >
                        <span>See more</span>
                        <img src={DropDownSvg} alt="..." className="w-4 ml-2" />
                      </div>
                    )}
                  </Form>
                </div>
              </div>

              <div className="px-5 py-7 bg-filter mb-[30px] rounded-[20px]">
                <div
                  className="flex justify-between"
                  onClick={() => handleDropdown("isHotelTheme")}
                >
                  <div className="border-none font-bold text-lg">
                    Tour Theme
                  </div>
                  <div className="cursor-pointer">
                    <FontAwesomeIcon
                      icon={isOpen.isHotelTheme ? faChevronUp : faChevronDown}
                    />
                  </div>
                </div>
                <div
                  className={`max-h-0 overflow-hidden transition-all duration-300 ${
                    isOpen.isHotelTheme ? "max-h-screen" : ""
                  }`}
                >
                  <Form form={formHotelTheme} className="mt-5">
                    <Form.Item name="hotelTheme">
                      <styled.CheckboxGroup
                        options={hotelThemeOption.slice(0, isHotelThemeItem)}
                        onChange={onChangeHotelTheme}
                      />
                    </Form.Item>

                    {isHotelThemeItem < hotelThemeOption.length && (
                      <div
                        onClick={() =>
                          setIsHotelThemeItem(isHotelThemeItem + 3)
                        }
                        className="text-primary cursor-pointer inline-flex justify-start border-b-[1px] border-b-primary border-solid "
                      >
                        <span>See more</span>
                        <img src={DropDownSvg} alt="..." className="w-4 ml-2" />
                      </div>
                    )}
                  </Form>
                </div>
              </div> */}
              </div>

              <div className="w-full lg:w-3/4 lg:ml-6">
                <div className="flex items-center text-gray mb-10">
                  <p className="hidden lg:block">
                    {showData?.length} tours found
                  </p>
                  <div className="center">
                    <div
                      onClick={() => setShowFilter(!showFilter)}
                      className="lg:hidden shadow-custom border-line px-5 py-2.5 rounded-3xl cursor-pointer"
                    >
                      <div className="flex justify-between">
                        <img src={filter} alt=".." className="w-5" />
                        <span className="ml-3 text-[#232323]">Filter</span>
                      </div>
                    </div>
                    <span
                      onClick={() => handleReset()}
                      className="text-primary ml-3 cursor-pointer"
                    >
                      Clear filter
                    </span>
                  </div>

                  <div className="flex ml-auto">
                    <styled.SpaceCompact block>
                      <Dropdown
                        placement="bottomRight"
                        overlay={
                          <>
                            <Radio.Group
                              onChange={onChangeRadioSort}
                              // value={isValueRadio}
                              className="z-10 absolute bg-white right-0 w-[150px] mt-4 text-black border-[1px] border-current border-solid border-slate-300 rounded-xl py-5 px-3 shadow-md shadow-slate-300"
                            >
                              <Radio value={"New Tour"}>New Tour</Radio>
                              <p className="mt-3">Price</p>
                              <Radio value={"asc"}> Low to Hight</Radio>
                              <br></br>
                              <Radio value={"desc"}> Hight to Low</Radio>
                            </Radio.Group>
                          </>
                        }
                        trigger={["click"]}
                      >
                        <div className="flex border-b border-solid border-[#232323] text-[#232323]">
                          Sort
                          <img src={DropDownSvg} alt="..." className="w-5" />
                        </div>
                      </Dropdown>
                    </styled.SpaceCompact>
                  </div>
                </div>
                {showData && showData?.length !== 0 ? (
                  <>
                    <div className="mb-[60px] grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                      {showData?.map((item) => (
                        <Card key={item.id} item={item} />
                      ))}
                    </div>

                    {/* <div className="flex justify-center">
                    <styled.PaginationCustom
                      current={current}
                      total={products?.length}
                      pageSize={products?.length}
                      onChange={handleChangePage}
                    />
                  </div> */}
                  </>
                ) : (
                  <div className="bg-[#fff3cd] text-[#664d03] border-[#ffecb5] border border-collapse p-5 shadow-custom">
                    No Tours found.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </BaseApp>
    </>
  );
}

import "antd/dist/antd";
import { Link } from "react-router-dom";
import ButtonShare from "../../component/buttonShare";
import { Rate } from "antd";

import size from "../../assets/svgs/size.svg";
import adults from "../../assets/svgs/adults.svg";
import child from "../../assets/svgs/child.svg";
import bed from "../../assets/svgs/bed.svg";
import airport from "../../assets/svgs/airport.svg";
import fitness from "../../assets/svgs/fitness.svg";
import heater from "../../assets/svgs/heater.svg";
import wifi from "../../assets/svgs/wifi.svg";
import restaurant from "../../assets/svgs/restaurant.svg";
import spa from "../../assets/svgs/spa.svg";
import washer from "../../assets/svgs/Washer&Dryer.svg";
import locationSvg from "../../assets/svgs/location.svg";
import bus from "../../assets/tours/multidaytour/All_Images/94.jpg"

import { Navigation, Autoplay, Pagination } from "swiper/modules";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import * as styled from "./style.js";
import BaseApp from "../../BaseComponent/BaseApp.js";

export default function DetailRoom(props) {
  return (
    <BaseApp>
      <div className="relative">
        <div className="py-5 border-bottom hidden sm:block">
          <div className="lg:container lg:mx-auto px-5">
            <ul className="flex items-center justify-start">
              <li className="relative pr-5">
                <Link to="/" className="text-primary ">
                  Home
                </Link>
                <div className="absolute top-[50%] right-0 w-1 h-1 bg-[#EAEEF3] z-10"></div>
              </li>

              <li className="relative ml-5 pr-5">
                <span>United States</span>
                <div className="absolute top-[50%] right-0 w-1 h-1 bg-[#EAEEF3] z-10"></div>
              </li>

              <li className="relative ml-5 pr-5">
                {/* <span>{nameHotel}</span> */}
                <div className="absolute top-[50%] right-0 w-1 h-1 bg-[#EAEEF3] z-10"></div>
              </li>

              <li className="ml-5">
                {/* <span className="text-gray">{roomItem?.nameRoom}</span> */}
              </li>
            </ul>
          </div>
        </div>
        <div className="flex justify-between mt-10 mb-6 lg:container lg:mx-auto px-5">
          <div>
            <h1 className="font-bold md:text-4xl text-3xl">
              {/* {roomItem?.nameRoom} */}
            </h1>
            <div className="flex mt-4 ml-2.5">
              <img className="w-4 mr-2" src={locationSvg} alt=".." />
              {/* <span className="text-gray">{location}</span> */}
            </div>
          </div>
          <div className="flex justify-between">
            <ButtonShare />
            <Rate
              character={
                <span
                  role="img"
                  aria-label="heart"
                  style={{
                    fontSize: "24px",
                    padding: "15px",
                    borderRadius: "100%",
                  }}
                >
                  ❤️
                </span>
              }
              count={1}
              className="center w-12 h-12 ml-3 border-line rounded-full shadow-custom hover:bg-[#d9303066]"
            />
          </div>
        </div>

        <div className="block px-5 h-[630px] mt-10">
          <Link
            className="md:w-1/3 w-full h-full md:inline-block block float-left md:pe-[5px]"
            to=""
          >
            <img
              className="w-full h-full md:rounded-bl-2xl md:rounded-tl-2xl md:rounded-br-none md:rounded-tr-none rounded-2xl object-cover"
              // src={images?.[0]}
              alt="image room"
            />
          </Link>

          <Link
            className="w-1/3 h-[50%] px-[5px] pb-[5px] md:inline-block hidden float-left"
            to=""
          >
            {/* <img className="w-full h-full" src={images?.[1]} alt="image room" /> */}
          </Link>

          <Link
            className="w-1/3 px-[5px] h-[50%] pb-[5px] md:inline-block hidden float-left"
            to=""
          >
            <img
              className="w-full h-full rounded-tr-2xl"
              // src={images?.[2]}
              alt="image room"
            />
          </Link>

          <Link
            to=""
            className="w-1/3 h-[50%] px-[5px] pt-[5px] md:inline-block hidden float-left"
          >
            {/* <img className="w-full h-full " src={images?.[3]} alt="image room" /> */}
          </Link>

          <Link
            className="w-1/3 h-[50%] px-[5px] pt-[5px] md:inline-block hidden float-left"
            to=""
          >
            <img
              className="w-full h-full rounded-br-2xl"
              // src={images?.[4]}
              alt="image room"
            />
          </Link>
        </div>

        <div className="pt-14 lg:container lg:mx-auto px-5">
          <div className="flex mx-[-12px]">
            <div className="lg:w-2/3 w-full px-3">
              <div className="flex border-bottom mb-10 pb-[25px] flex-wrap">
                <div className="flex mb-[15px] lg:mb-0 w-1/2 sm:w-1/5">
                  <div className="border-[1px] border-solid rounded-xl border-gray-300 shadow-xl shadow-grey w-11 h-11 p-2 flex item-center ">
                    <img className="" src={size} alt="..." />
                  </div>
                  <p className="ml-2.5 items-center text-gray text-sm flex">
                    {/* S: {S} */}
                  </p>
                </div>
                <div className="flex mb-[15px] lg:mb-0 w-1/2 sm:w-1/5">
                  <div className="border-[1px] border-solid rounded-xl border-gray-300 shadow-xl shadow-grey w-11 h-11 p-2 flex item-center ">
                    <img className="" src={bed} alt="..." />
                  </div>
                  <p className="ml-2.5 items-center text-gray text-sm flex">
                    {/* Beds: {Beds} */}
                  </p>
                </div>
                <div className="flex mb-[15px] lg:mb-0 w-1/2 sm:w-1/5">
                  <div className="border-[1px] border-solid rounded-xl border-gray-300 shadow-xl shadow-grey w-11 h-11 p-2 flex item-center ">
                    <img className="" src={adults} alt="..." />
                  </div>
                  <p className="ml-2.5 items-center text-gray text-sm flex">
                    {/* Adults: {Adults} */}
                  </p>
                </div>
                <div className="flex mb-[15px] lg:mb-0 w-1/2 sm:w-1/5">
                  <div className="border-[1px] border-solid rounded-xl border-gray-300 shadow-xl shadow-grey w-11 h-11 p-2 flex item-center ">
                    <img className="" src={child} alt="..." />
                  </div>
                  <p className="ml-2.5 items-center text-gray text-sm flex">
                    {/* Children: {Children} */}
                  </p>
                </div>
              </div>

              <div>
                <h2 className="text-3xl font-semibold mb-10">
                  About this room
                </h2>
                {/* {description?.map((item, index) => (
                <p key={index} className="text-base mb-5">
                  {item}
                </p>
              ))} */}
              </div>

              <hr className="my-10 text-slate-200"></hr>

              <div>
                <h2 className="text-3xl font-bold my-5">Hotel Facilities</h2>
                <div className="flex flex-col sm:flex-row">
                  <ul className="w-1/3">
                    <li className="text-gray">
                      <div className="flex items-center pb-5">
                        <img className="w-7 mr-5" src={airport} alt="..." />
                        <p>AirPort TransPort</p>
                      </div>
                    </li>
                    <li className="text-gray">
                      <div className="flex items-center pb-5">
                        <img className="w-7 mr-5" src={fitness} alt="..." />
                        <p>Fitness Center</p>
                      </div>
                    </li>
                    <li className="text-gray">
                      <div className="flex items-center pb-5">
                        <img className="w-7 mr-5" src={heater} alt="..." />
                        <p>Heater</p>
                      </div>
                    </li>
                  </ul>

                  <ul className="w-1/3">
                    <li className="text-gray">
                      <div className="flex items-center pb-5">
                        <img className="w-7 mr-5" src={wifi} alt="..." />
                        <p>Internet-Wifi</p>
                      </div>
                    </li>
                    <li className="text-gray">
                      <div className="flex pb-5 items-center">
                        <img className="w-7 mr-5" src={restaurant} alt="..." />
                        <p>Restaurant</p>
                      </div>
                    </li>
                  </ul>

                  <ul className="w-1/3">
                    <li className="text-gray">
                      <div className="flex pb-5 items-center">
                        <img className="w-7 mr-5" src={washer} alt="..." />
                        <p>Washer & Dryer</p>
                      </div>
                    </li>
                    <li className="text-gray">
                      <div className="flex pb-5 items-center">
                        <img className="w-7 mr-5" src={spa} alt="..." />
                        <p>Spa & Sauna</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <hr className="my-10 text-slate-200"></hr>

              <div>
                <h2 className="text-2xl font-bold mb-10">
                  Rates & availability
                </h2>
                <div className="w-full rounded-xl shadow-custom bg-white border-line"></div>
              </div>

              <div className="mb-10 lg:hidden block">
                <div className="border-line rounded-2xl py-10 mt-10">
                  <div className="flex justify-center items-center">
                    <img
                      className="rounded-full cursor-pointer w-22"
                      src={bus}
                      alt="..."
                    />
                  </div>
                  <p className="cursor-pointer text-bold text-center py-3">
                    modtel
                  </p>
                  <p className="text-gray text-center ">Member Since 2022</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="md:my-[70px] my-[30px]" />
        </div>

        <div className="lg:container lg:mx-auto px-5 overflow-hidden">
          <h3 className="text-3xl font-bold mb-[70px] mt-5">
            Explore other options
          </h3>
          <div className="flex flex-row flex-wrap">
            <styled.SwiperCustom
              slidesPerView={3}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
              breakpoints={{
                300: {
                  slidesPerView: 1,
                  spaceBetween: 24,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 24,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 24,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 24,
                },
                1366: {
                  slidesPerView: 3,
                  spaceBetween: 24,
                },
              }}
            ></styled.SwiperCustom>
          </div>
        </div>
      </div>
    </BaseApp>
  );
}

import { useState } from "react";
import Whatsapp from "../../assets/whatsapp.svg";
import { Input } from "antd";
import { SendOutlined } from "@ant-design/icons";
import axios from "axios";
// import { APIURL } from "../../App";

export default function Anchor() {
  const [openModel, setOpenModel] = useState(false);
  const [ques, setques] = useState("");

  const handleWhatsAppClick = () => {
    const phoneNumber = "+3547629948";
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink, "_blank");
    // setOpenModel(!openModel);
  };

  const openaiApiKey = "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";
  const apiUrl = "https://api.openai.com/v1/completions";

  const OpenAI = async (question) => {
    console.log("question: ", question);
    try {
      let res = axios.post(`${apiUrl}/api/chat`, question);
      console.log("res: ", res);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <div className="flex h-[74px] bg-transparent fixed bottom-24 z-0 right-0">
      {/* <div className="w-[80px] text-[6px] px-2 py-3">
        <img
          className="w-full px-2"
          src="https://www.gstatic.com/recaptcha/api2/logo_48.png"
          alt=".."
        />
        Bảo mật . Điều Khiển
      </div>
      <div className="px-3 py-4 bg-[#1a73e8]">
        <p className="text-md font-semibold text-white">
          Được bảo vệ bằng reCAPTCHA
        </p>
        <div className="flex">
          <a className="text-sm text-white" href="#">
            Điều Kiện
          </a>
          <a className="text-sm text-white" href="#">
            Bảo Mật
          </a>
        </div>
      </div> */}
      <img
        onClick={handleWhatsAppClick}
        className="w-full px-2 cursor-pointer"
        src={Whatsapp}
        alt=".."
      />
      {/* {openModel && (
        <div
          style={{
            width: "350px",
            height: "450px",
            border: "1px solid red",
            background: "white",
            position: "fixed",
            zIndex: 0,
            bottom: "160px",
            right: "40px",
            borderRadius: "10px",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
            padding: "10px",
          }}
        >
          <div>
            <img src="" />
            <h1>ChatBot</h1>
          </div>
          <div></div>
          <div>
            <Input
              onChange={(e) => setques(e.target.value)}
              placeholder="Text Your Message"
              suffix={<SendOutlined onClick={() => OpenAI(ques)} />}
            />
          </div>
        </div>
      )} */}
    </div>
  );
}

import MapSvg from "../../assets/svgs/map.svg";
import EmailSvg from "../../assets/svgs/email.svg";
import { Form, Input, Button } from "antd";
import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import * as styled from "./style";
import HelmetSEO from "../../component/SEO/Helmet";
import BaseApp from "../../BaseComponent/BaseApp";
import emailjs from "@emailjs/browser";
import "./contact.css";

export default function Contact() {
  const [done, setDone] = useState(false);

  const formRef = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_uw9qde6",
        "template_nzah8cn",
        formRef.current,
        "arO0uEd5DnonNwYK1"
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setDone(true);
          formRef.current.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  return (
    <>
      <HelmetSEO
        title={"Contact Us - Arctic Explorer Adventure"}
        content={
          "Get in touch with Arctic Explorer Adventure to start planning your Icelandic adventure today! Whether you have questions about our tours or need assistance with booking, our friendly team is here to help. Reach out to us now!"
        }
        keywords={
          "Contact Arctic Explorer Adventures, Iceland travel contact, Iceland tours contact, Iceland coach rental contact, Iceland travel experts, Arctic Explorer Adventures contact, book Iceland tour, Iceland travel assistance, Iceland trip planning, Iceland tour inquiries"
        }
      />
      <BaseApp>
        <div className="w-full lg:h-[780px] h-[400px]">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2929060.164334403!2d-21.588993266263206!3d64.7678072806194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48d22b52a3eb6043%3A0x6f8a0434e5c1459a!2sIceland!5e0!3m2!1sen!2sin!4v1707141974292!5m2!1sen!2sin"
            frameBorder="0"
            className="w-full h-full border-0"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        <div className="py-[50px] lg:py-[80px] mx-5 lg:mx-auto lg:container">
          <div className="flex flex-wrap">
            <div className="w-full  lg:w-1/2 my-auto order-2 lg:order-1">
              <h1 className="mb-4 text-3xl font-bold">BUISNESS HOURS:</h1>
              <p className="mb-4 text-gray">
                <b>
                  Our service desk is open every day From 7:00 am – 9:00 pm GMT
                  (Monday - Sunday). You are always welcome to send us an e-mail
                  using our contact form. Our friendly customer service will
                  assist you every day during business hours.
                </b>
              </p>
              <p className="mb-4 text-gray">
                Address: Iceland , reykjavik , vogaland16 , 108 postcode
              </p>
              <div className="flex items-center py-4">
                <img className="pr-2.5 w-10" src={MapSvg} alt="..." />
                <span className="text-lg">Iceland</span>
              </div>
            </div>

            <div
              className="w-full lg:w-1/2 my-auto order-2 lg:order-1"
              style={{ padding: "50px" }}
            >
              <h2 className="mb-4 text-3xl font-bold">Customer Care:</h2>
              <p className="mb-4 text-black">
                <b>Monday - Sunday</b>
              </p>
              <p className="mb-4 text-black">7:00 am - 9:00 pm GMT</p>
            </div>

            <div className="w-full lg:w-1/2 my-auto order-2 lg:order-1">
              <h2 className="mb-4 text-3xl font-bold">Any Queries?</h2>
              <p className="mb-4 text-black">
                Talk to our experts:{" "}
                <a class="font-weight-bold" href="tel:+354 762 9948">
                  <FontAwesomeIcon icon={faMobileAlt} />
                  <a
                    href="https://wa.me/3547629948"
                     target="_blank" rel="noopener noreferrer"
                    style={{ fontWeight: "bold", color: "green" }}
                  >
                    +354 762 9948
                  </a>
                </a>
              </p>
              {/* <div className="flex items-center py-4">
                <img className="pr-2.5 w-10" src={EmailSvg} alt="..." />
                <span className="text-lg">
                  arcticexploreradventures@gmail.com
                </span>
              </div> */}
            </div>

            <div className="w-full lg:w-1/2 shadow-custom rounded-3xl order-1 lg:order-2 mb-10 lg:mb-0">
              <form
                className="py-[30px] px-[18px]"
                ref={formRef}
                onSubmit={sendEmail}
              >
                <h2 className="text-3xl font-bold mx-3">Send a message</h2>
                <div className="flex mt-[30px]">
                  <styled.FormItemMessages
                    name="name"
                    label="Your Name"
                    rules={[
                      {
                        required: true,
                        message: "Name is required",
                      },
                    ]}
                    style={{
                      width: "50%",
                      padding: "0 12px",
                    }}
                  >
                    <input
                      type="text"
                      name="from_name"
                      className="user"
                      required="true"
                      style={{
                        width: "100%",
                        padding: "12px 15px",
                        boxShadow:
                          "0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05)",
                        borderRadius: "12px",
                      }}
                      placeholder="Your Name*"
                    />
                  </styled.FormItemMessages>

                  <styled.FormItemMessages
                    name="phone"
                    label="Your Phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                    ]}
                    style={{
                      width: "50%",
                      padding: "0 12px",
                    }}
                  >
                    <input
                      type="text"
                      name="phone_number"
                      required="true"
                      className="user"
                      style={{
                        width: "100%",
                        padding: "12px 15px",
                        boxShadow:
                          "0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05)",
                        borderRadius: "12px",
                      }}
                      placeholder="Your Phone"
                    />
                  </styled.FormItemMessages>
                </div>

                <styled.FormItemMessages
                  name="email"
                  label="Email ID"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                  style={{
                    padding: "0 12px",
                  }}
                >
                  <input
                    type="email"
                    required="true"
                    name="user_email"
                    className="user"
                    style={{
                      width: "100%",
                      padding: "12px 15px",
                      boxShadow:
                        "0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05)",
                      borderRadius: "12px",
                    }}
                    placeholder="Your Email*"
                  />
                </styled.FormItemMessages>

                <styled.FormItemMessages
                  name="content"
                  label="Message"
                  style={{
                    padding: "0 12px",
                  }}
                >
                  <textarea
                    name="message"
                    className="user"
                    placeholder="Message"
                    rows={7}
                    style={{
                      boxShadow:
                        "0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05)",
                    }}
                  />
                </styled.FormItemMessages>

                <input
                  type="submit"
                  value="send"
                  className="button"
                  style={{
                    width: "100%",
                    height: "unset",
                    padding: "15px",
                    color: "#fff",
                    backgroundColor: "#3B71FE",
                    borderRadius: "50px",
                    fontWeight: "700",
                  }}
                />
                <span style={{ color: "green", textAlign: "center" }}>
                  {done && "Thanks for contacting me!"}
                </span>
              </form>
            </div>
          </div>
        </div>
      </BaseApp>
    </>
  );
}
